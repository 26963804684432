@use 'sass:color';
@use 'sass:list';
@use 'sass:math';
@use '../../tools' as *;

$_transparent-pixel: url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
$_asym-radius: #{rem(5)} #{rem(5)} #{rem(30)};
$_quote-icon-color: #e1e3f2;

// The classes and selectors for RoundBox are intended to be implemented
// as modifiers to allow for a variety of combinations and options.
// `.roundbox` should be applied as a base class, with additional modifier
// classes like `.rb-[modifier]` (e.g., `.rb-dark`).

%_relative {
  position: relative;
}

// Baseline RoundBox styles
%roundbox-base {
  @extend %_relative;
  @extend %clearfix;

  --rb-radius: var(--rb-radius-asymmetric);
  --rb-background: #{$white};
  --rb-shadow: #{$tile-shadow};
  --rb-color: #{$text-general};
  --rb-lh-body: #{$lh-body};

  margin: 2rem 0;
  display: flow-root;
  color: var(--rb-color, #{$text-general});

  img {
    @extend %_relative;

    display: block;
    border-radius: rem(5);
    z-index: 1;
    box-shadow: var(--rb-shadow, none);
  }

  .content {
    @extend %_relative;
    @extend %remove-child-bookend-margins;

    line-height: var(--rb-lh-body);

    p {
      line-height: inherit;
    }
  }
}

// The portion of the RoundBox with the border radius and either box shadow or fill color
%roundbox-rounded-box {
  border-radius: var(--rb-radius, #{$_asym-radius});
  background: var(--rb-background, #fff);
  box-shadow: var(--rb-shadow, none);

  @include print {
    border: 1px solid $button-outline;
    box-shadow: none !important;
    color: $text-general !important;
    background-color: $white !important;
  }
}

// Floated image with text that wraps and buttons beneath
%roundbox-buttons {
  --rb-button-bgcolor: rgba(255, 255, 255, 0.8);
  --rb-button-bgcolor-hover: rgba(230, 230, 230, 0.8);

  font-family: $open-sans;
  font-weight: $semibold;

  img {
    display: block;
    float: right;
    max-width: 7rem;
    max-height: 7rem;
    margin-left: 0.5rem;
    margin-right: 1.5rem;
    margin-bottom: 0.25rem;
    shape-outside: inset(0 0 2.5rem -0.5rem); // Adjust text wrapping for the offset image and for Safari
    box-shadow: none;
  }

  .content {
    @extend %roundbox-rounded-box;

    border-radius: rem(10);
    padding: 1.5rem;
  }

  img + .content {
    top: 2.5rem;
    min-height: 8rem; // Encompass, at a minimum, a square image
    margin-bottom: 2.5rem; // Negate top value to account for offset
  }

  .text {
    @extend %remove-child-bookend-margins;
  }

  .text + .buttons {
    margin-top: 1.5rem;

    &:not(:has(a)) {
      margin-top: 0;
    }
  }

  .buttons {
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem 2rem;

    & > * {
      display: flex;
      position: relative;
      flex: 1 0 auto;
      align-items: center;
      justify-content: center;
      min-width: 200px;
      max-width: 300px;
      width: calc(50% - 1rem);
      border: 1px solid $button-outline;
      border-radius: rem(3);
      padding: 1rem;
      overflow: hidden;
      background-color: var(--rb-button-bgcolor);
      text-align: center;
      transition: background-color ease-in-out 100ms;

      &:empty {
        display: none;
      }

      &:hover {
        background-color: var(--rb-button-bgcolor-hover);
      }

      & > a {
        color: inherit;
        text-decoration: inherit;

        &::before {
          content: '';
          display: block;
          position: absolute;
          inset: 0;
        }
      }
    }

    a:is(:link, :hover, :active, :visited) {
      text-decoration: none;
      color: var(--rb-color);
    }

    a.exit-link::after {
      @extend %icon-base;

      content: $i-exit;
      margin-left: 0.5em;
      display: inline;
    }

    a.exit-nih::after {
      content: $i-exit-nih;
    }

    .eld {
      display: none;
    }
  }

  @include desktop(true) {
    img {
      margin-left: 2rem;
      margin-right: 2.5rem;
      max-width: 8rem;
      max-height: 8rem;
      shape-outside: inset(0 0 2.5rem -2rem); // Adjust text wrapping for the offset image and for Safari
    }

    .content {
      padding: 1.5rem 2rem;
    }

    .buttons {
      gap: 1.5rem 2rem;
    }
  }
}

// Dark filled RoundBox (no shadow, white text and links)
%roundbox-inverted-links {
  $_rb-text-color: var(--rb-color, #{$white});
  --cta-color: #{$_rb-text-color};
  --icon-color: #{$_rb-text-color};

  a:is(:link, :hover, :visited, :active) {
    color: inherit;
  }

  a {
    text-decoration: underline;

    &:is(:hover, :active) {
      text-decoration: none;
    }
  }

  .buttons {
    --rb-color: #{$text-dark};
    --rb-button-bgcolor: rgba(255, 255, 255, 0.7);
    --rb-button-bgcolor-hover: rgba(255, 255, 255, 0.85);
  }
}

%roundbox-quote {
  --rb-lh-body: 1.25;

  margin: 4rem 0 0;
  font-size: rem(16);

  .content {
    .quote-statement {
      &, * {
        font-size: rem(16);
      }
    }

    .quote-attribution {
      width: 100%;

      .quote-source {
        margin-bottom: 0.5rem;

        &, * {
          font-size: rem(16);
        }
      }

      .quote-bio {

        margin: 0;

        &, * {
          font-size: rem(12);
          line-height: 1.5;
        }
      }
    }

    :is(.quote-statement, .quote-source, .quote-bio) {
      @extend %remove-child-bookend-margins;
    }
  }

  @include desktop(true) {
    .content {
      display: flex;
      flex-wrap: wrap;

      img {
        margin-top: -3rem;
        margin-left: 0;
        margin-bottom: auto;
      }
    }
  }

  @include mobile {
    &:has(img) {
      margin-top: 2rem;
    }

    .content {
      img {
        margin: -9rem 0 0.5rem auto;
      }
    }
  }
}

%roundbox-quote-icon {
  position: absolute;
  font-family: $dk-icons;
  font-size: 3rem;
  line-height: normal;
  color: var(--rb-quote-color, #{$_quote-icon-color});
}

%roundbox-quote-light {
  margin-bottom: 2rem;

  &:not(:has(img)) {
    margin-top: 3.5rem;
  }

  img {
    margin-left: 0;
    border-radius: rem(10);
    box-shadow: $tile-shadow;
  }

  .content {
    border-radius: rem(10);

    &.content {
      padding-top: 2rem;
    }

    .quote-statement::before {
      @extend %roundbox-quote-icon;

      content: $i-quote-open;
      top: -1.5rem;
      margin-left: 1.5rem;
    }

    .quote-source {
      margin-top: 1rem;
    }
  }

  &.rb-right {
    .quote-attribution {
      text-align: right
    }

    @include desktop(true) {
      &:has(img) {
        padding-right: 1.5rem;

        .content {
          flex-direction: row-reverse;

          img {
            right: -3rem;
            margin-left: -1.5rem;
            margin-right: 0;
          }
        }
      }
    }
  }

  @include desktop(true) {
    &:not(.rb-right) {
      &:has(img) {
        padding-left: 1.5rem;
      }

      img {
        left: -3rem;
        margin-right: -1.5rem;
      }
    }

    .quote-statement {
      flex: 1;
      margin-bottom: 0.5rem;
    }
  }

  @include mobile {
    img {
      right: -3rem;
      margin-top: -9.5rem;
      margin-left: auto;
    }

    .content:has(img) {
      margin-top: 7.5rem;
      margin-left: 0;
      margin-right: 1.5rem;

      .quote-statement {
        margin-top: 1rem;
      }

      .quote-attribution {
        text-align: right
      }
    }
  }
}

%roundbox-quote-dark {
  margin-bottom: 3rem;

  .content {
    font-family: $open-sans;

    .quote-attribution {
      position: relative;
      flex: 1;

      .quote-statement {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      &::before, &::after {
        @extend %roundbox-quote-icon;
      }

      &::before {
        content: $i-quote-open;
        top: -3.5rem;
        margin-left: 1rem;
      }

      &::after {
        content: $i-quote-close;
        bottom: -3.5rem;
        right: 0;
        margin-right: 1rem;
      }
    }
  }

  @include desktop(true) {
    &.rb-right {
      img {
        order: 2;
        margin-left: 1.5rem;
        margin-right: 0;
      }

      .quote-attribution {
        footer {
          margin-right: 1rem;
        }
      }
    }
  }

  @include mobile {
    .content:has(img) {
      margin-top: 7.5rem;
    }
  }
}

// Floated image with longer content that wraps
%roundbox-prose {
  .content {
    @extend %roundbox-rounded-box;

    padding: 1.5rem;
  }

  img {
    display: block;
    margin-left: 1.5rem;
    max-width: 8.5rem;
  }

  img + .content {
    top: -1rem;
    padding-top: 2rem;
    margin-bottom: -1rem; // Negate top value to a account for offset
  }

  @include desktop(true) {
    img + .content {
      top: 1.5rem;
      min-height: 11.5rem; // Encompass, at a minimum, a square image
      padding-top: 1.5rem;
      margin-bottom: 1.5rem; // Negate top value to account for offset
    }

    img {
      float: left;
      margin-right: 1.5rem;
      margin-bottom: 0.25rem;
      max-width: 11.5rem;
      shape-outside: inset(0 -1.5rem 1.5rem 0); // Adjust text wrapping for the offset image and for Safari
    }
  }
}

// RoundBox modifier classes
%roundbox-modifiers {
  // RoundBox modifier classes
  &:is(.rb-fill, [class*="bg-"]) {
    --rb-background: #{$bg-sea-glass}; // Sea Glass background unless overridden
    --rb-shadow: none;
  }

  $_darkbg: 'bg-charcoal', 'bg-onyx', 'bg-dark-cyan', 'bg-dark-blue', 'bg-lavender'; // Colors needing white text
  &:is(.rb-dark, #{join-classes($_darkbg)}) {
    --rb-background: #{$bg-lavender}; // Lavender background unless overridden
    --rb-shadow: none;
    --rb-color: #{$white};

    @extend %roundbox-inverted-links;
  }

  &.rb-serif {
    font-family: $merriweather;
  }

  &.rb-sans {
    font-family: $open-sans;
  }

  // Background color options (expected refactor later)
  &[class*="bg-"] {
    background-color: transparent;
  }

  @each $class, $color in $background-colors {
    &.#{$class} {
      --rb-background: #{$color};
    }
  }
}

// ****************************
// * Define custom properties *
// ****************************

body {
  --rb-radius-symmetric: #{rem(10)};
  --rb-radius-asymmetric: #{$_asym-radius};
}

// *****************************
// * Baseline RoundBox styling *
// *****************************

.roundbox {
  @extend %roundbox-base;
  @extend %roundbox-modifiers;
}

// ***********************
// * RoundBox (Standard) *
// ***********************

.roundbox:not(:is(.rb-prose, .rb-buttons, .rb-quote)) {
  $_content-min-width-default: math.percentage(math.div(400, 660)); // Default value for minimum content width
  $_image-max-height-default: #{rem(220)}; // Default value for max image height
  $_content-min-width-floor: 40%; // Minimum allowable content width
  $_image-max-height-ceiling: #{rem(270)}; // Maximum allowable image height

  --rb-content-min-width: #{$_content-min-width-default};
  --rb-image-max-height: #{$_image-max-height-default};

  // Use pseudoelement for background color/shape on desktop (hidden on mobile)
  &::before {
    @extend %roundbox-rounded-box;
  }

  .content {
    // Apply background color/shape to .content element on mobile
    @extend %roundbox-rounded-box;

    padding: 1.5rem;
  }

  :is(h2, h3, h4, h5, h6) {
    @extend %size-h4;
    @extend %font-heading;
  }

  img {
    max-height: min(var(--rb-image-max-height), #{$_image-max-height-ceiling});
  }

  @include mobile {
    &.rb-right {
      .content {
        margin-inline: 0 1.5rem;
      }

      img {
        margin-left: auto;
      }
    }

    .content:where(:not(:first-child:last-child)) {
      margin-top: -1.5rem;
      margin-inline: 1.5rem 0;
      padding-top: 2.5rem;
    }

    img {
      max-width: min(#{rem(400)}, 100% - 1.5rem);
    }
  }

  @include desktop(true) {
    $_content-minmax: minmax(max(var(--rb-content-min-width), #{$_content-min-width-floor}), 1fr);

    display: grid;
    grid-template: 'image content' auto / auto #{$_content-minmax};

    // Use pseudoelement for background color on desktop
    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
    }

    &:has(img)::before {
      inset: 1.5rem 0 0 1.5rem;
    }

    &.rb-right {
      grid-template: 'content image' auto / #{$_content-minmax} auto;

      &:has(img)::before {
        inset: 1.5rem 1.5rem 0 0;
      }

      img {
        margin-bottom: 2.5rem;
      }
    }

    .content {
      margin-top: 1.5rem;

      // Reverse mobile styles
      background: none;
      border-radius: 0;
      box-shadow: none;

      &:first-child:last-child {
        grid-column: span 2;
        margin-top: 0;
      }
    }

    img {
      grid-area: image;
      margin-bottom: 1.5rem;

      & + .content {
        grid-area: content;
      }
    }
  }
}

// ******************
// * RoundBox Prose *
// ******************

.roundbox.rb-prose {
  @extend %roundbox-prose;
}

// ********************
// * RoundBox Buttons *
// ********************

.roundbox.rb-buttons {
  @extend %roundbox-buttons;

  --rb-radius: var(--rb-radius-symmetric);
}

// ******************
// * RoundBox Quote *
// ******************

.roundbox.rb-quote {
  @extend %roundbox-quote;
  @extend %roundbox-prose;

  --rb-quote-color: #{$_quote-icon-color};

  &:is(.rb-fill,.rb-dark,[class*="bg-"]) {
    @extend %roundbox-quote-dark;
  }

  &:not(:is(.rb-fill,.rb-dark,[class*="bg-"])) {
    @extend %roundbox-quote-light;

    --rb-radius: var(--rb-radius-symmetric);
  }
}

// *****************
// * RoundBox Hero *
// *****************

.roundbox-hero {
  --rh-background: #{$bg-dark-blue};
  --rh-image: #{$_transparent-pixel};
  --rh-image-width: 100%; // --rh-image-width-mobile intentionally undefined; define inline if needed

  position: relative;
  margin: 2rem 0;
  border-radius: var(--rh-radius, var(--rb-radius-asymmetric));
  background: var(--rh-background);
  color: $white;
  overflow: hidden;

  &:first-child {
    margin-top: 0.5rem;
  }

  :is(.title,h1,h2) {
    @extend %font-heading;
    @extend %size-h2;
  }

  .detail {
    @extend %dk-paragraph-text;
  }

  &.rh-title {
    .content {
      gap: 1.5rem 0;
    }

    :is(.title,h1,h2) {
      @extend %size-h1;
    }

    .detail {
      @extend %font-heading;
      @extend %size-h3;
    }
  }

  &.rh-animated .content {
    &::before {
      transform: scale(1.5) translateX(-100%);
      animation: roundbox-hero-img-shrink 1s ease-in-out 500ms forwards;
    }

    .slide-up {
      position: relative;
      transform: translateY(100%);
      animation: roundbox-hero-slide-up 1s ease-in-out 500ms forwards;
    }

    @media (prefers-reduced-motion: reduce) {
      &::before, .slide-up {
        animation: none;
        transform: none;
      }
    }
  }

  .content {
    display: grid;
    position: relative;
    z-index: 1;
    grid-template: 'image title' auto 'image content' auto / 1fr 4fr;
    gap: 2.5rem 0;
    overflow: hidden;

    // Accent artwork for left side of hero
    &::before {
      grid-area: image;
      content: '';
      display: block;
      width: var(--rh-image-width-mobile, var(--rh-image-width));
      height: 100%;
      z-index: -1;
      background: var(--rh-image) no-repeat right center;
      background-size: cover;
    }

    & > *, p {
      margin: 0;
    }

    p + p {
      margin-top: 0.5rem;
    }

    & > :first-child {
      grid-area: title;
      align-self: end;
      margin-top: 2rem;
      margin-right: 1.5rem;
    }

    & > :last-child {
      grid-area: content;
      padding-bottom: 1.5rem;
      margin-right: 1.5rem;
    }

    & > :not(:first-child,:last-child) {
      display: none;
    }
  }

  @include desktop(true) {
    .content {
      grid-template: 'image title' minmax(8rem, auto) 'image content' auto / 1fr 3fr;

      &::before {
        width: var(--rh-image-width);
      }
    }

    &.rh-title .content > :last-child {
      padding-bottom: 3.5rem;
    }
  }
}

@keyframes roundbox-hero-img-shrink {
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes roundbox-hero-slide-up {
  to {
    transform: translateY(0);
  }
}
