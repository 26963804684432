// Image resources component (e.g., NKDEP lesson builder)

@use '../../tools' as *;

.image-resources {
  list-style: none;
  margin-top: 1.5rem;

  @include row;

  & > * {
    display: block;
    margin: 0;
    list-style: none;
    margin-bottom: 1.5rem;

    & > :first-child {
      display: block;
      position: relative;
      border: solid 1px $divider-stroke;
      padding-bottom: calc(100% - 2px);
      overflow: hidden;

      & > :first-child {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        & > :first-child {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }

      * {
        display: none;
      }
    }

    & > :last-child {
      display: block;
      line-height: 1.8rem;
      margin-top: rem(10);

      & > * {
        margin: rem(8) 0;
      }

      @extend %remove-child-bookend-margins;
    }

    @include desktop {
      @include span-columns(6 of 12);

      &:nth-child(2n+1) {
        clear: left;
      }

      &:nth-child(2n) {
        @include omega;
      }
    }
  }

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
