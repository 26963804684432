@use 'sass:list';
@use 'sass:map';

// Base font size (equal to 1rem)
$em-base: 14px !default;

// Percentage of $em-base to use as 1rem for tablet sizes
$tablet-scale: 0.85 !default;

// Minimum pixel width of each responsive breakpoint
$xl-min:           1440px !default;
$desktop-min:      1025px !default;
$tablet-min:        650px !default;
$fixed-layout-min:  890px !default;

// Design container width
$container-width: $desktop-min !default;
$container-pad:   30px 0 !default; // (desktop, mobile)

// Calculated maximum widths
$desktop-max:         $xl-min - 1px;
$tablet-max:          $desktop-min - 1px;
$mobile-max:          $tablet-min - 1px;
$variable-layout-max: $fixed-layout-min - 1px;

// Grayscale colors
$white:             #fff;
$light-gray:        #999;
$medium-gray:       #666;
$dark-gray:         #333;
$black:             #000;

// Typographic colors
$text-light:        #fff !default;
$text-general:      #575757 !default;
$text-dark:         #333 !default;
$text-green:        #426924;
$text-red:          #eb0000;
$text-blue:         #0067a8;
$text-teal:         #307682;
$link-standard:     #0072bc !default;
$link-hover:        #004b8d !default;
$link-visited:      #c35522 !default;

// Site element colors
$bg-outer-body:     #eeeded !default;
$site-nav:          #213882 !default;
$site-nav-current:  #1365b1 !default;
$divider-stroke:    #c1c2c2;
$button-gray:       #f5f5f5;
$button-outline:    #c0c0c0;
$button-text:       #5b584b;
$block-accent-gray: #d3d6df;
$twitter-blue:      #1d9bf0;
$facebook-blue:     #1877f2;
$linkedin-blue:     #0a66c2;
$x-social-black:    #2f3743;
$researchgate-teal: #00d0af;
$rss-orange:        #ee8b14;
$youtube-red:       #ff0000;
$instagram-purple:  #bf2f98;

// Left navigation colors
$ln-parent:         #294484 !default;
$ln-current:        #c2d8ed !default;
$ln-current-child:  #f2f6fb !default;

// Background colors
$bg-white:          #fff;
$bg-ash:            #f6f5f5;
$bg-gray:           #f2f2f2;
$bg-charcoal:       #474945;
$bg-onyx:           #2a2b28;
$bg-dark-cyan:      #2a586a;
$bg-blue-green:     #085863;
$bg-lavender:       #3e4573;
$bg-dark-blue:      #23325e;
$bg-sea-foam:       #c7dcda;
$bg-sea-glass:      #ecf4f7;
$bg-honeydew:       #f2f7f5;
$bg-tab-nav:        #dee0e7;
$bg-red:            #a20000;
$bg-peach:          #eacb9a;

// 'name': border-color [background-color [color]]
// The last hex for each color is used for any subsequent values
$accent-color-map: (
  'green':         #5f8038,
  'indigo':        #24325f,
  'blue':          #1568af,
  'orange':        #a76903,
  'gray':          #808080 #757575,
  'teal':          #45a9ba #45a9ba #307682,
  'mauve':         #bd899b #bd899b #a05972,
  'lavender':      #636e8f #636e8f #444b62,
  'teal-dark':     #307682,
  'mauve-dark':    #a05972,
  'lavender-dark': #444b62,
  'cyan':          #547988,
  'dirblue':       #0072bc,
  'sea':           #55b9ad,// #358279,
  'bondi':         #03a2c2,// #037d96,
  'orchid':        #6f7da4,// #62769d,
  'gold':          #e19a0f,// #9e6d0a,
  'wine':          #9e6c7d,// #966475
) !default;

$default-accent-color-name: 'green' !default;
$default-accent-color: map.get($accent-color-map, $default-accent-color-name) !default;

// Legacy accent color variables
$indigo: list.nth(map.get($accent-color-map, 'indigo'), 1);
$blue:   list.nth(map.get($accent-color-map, 'blue'), 1);
$orange: list.nth(map.get($accent-color-map, 'orange'), 1);
$green:  list.nth(map.get($accent-color-map, 'green'), 1);
$gray:   list.nth(map.get($accent-color-map, 'gray'), 1);

$color-themes: (
  blue:     (list.nth(map.get($accent-color-map, 'blue'), 1),     #336b9c, #0a1c2b, 0.85),
  green:    (list.nth(map.get($accent-color-map, 'green'), 1),    #5d8339, #181e12, 0.85),
  indigo:   (list.nth(map.get($accent-color-map, 'indigo'), 1),   #665285, #1a1231, 0.85),
  lavender: (list.nth(map.get($accent-color-map, 'lavender'), 1), #444b62, #15171d, 0.85),
  teal:     (list.nth(map.get($accent-color-map, 'teal'), 1),     #285f75, #021e29, 0.85),
  mauve:    (list.nth(map.get($accent-color-map, 'mauve'), 1),    #a05972, #24181c, 0.85)
) !default;

$background-colors: (
  bg-ash:       $bg-ash,
  bg-gray:      $bg-gray,
  bg-honeydew:  $bg-honeydew,
  bg-sea-foam:  $bg-sea-foam,
  bg-sea-glass: $bg-sea-glass,
  bg-charcoal:  $bg-charcoal,
  bg-dark-cyan: $bg-dark-cyan,
  bg-dark-blue: $bg-dark-blue,
  bg-lavender:  $bg-lavender,
  bg-onyx:      $bg-onyx
) !default;

// Box shadows
$tile-shadow: 0 .0714285714rem .4285714286rem $button-outline;

// Fonts
$montserrat:     "Montserrat", "Montserrat-local", "Montserrat-fallback", Verdana, Helvetica, sans-serif;
$open-sans:      "Open Sans", "Open Sans-local", "Open Sans-fallback", Verdana, Helvetica, sans-serif;
$merriweather:   "Merriweather", "Merriweather-local", "Merriweather-fallback", Georgia, serif;
$dk-icons:       "dki";

$font-icon:     $dk-icons !default;
$font-serif:    $merriweather !default;
$font-sans:     $open-sans !default;
$font-sans-alt: $montserrat !default;

// Font Weights
$semibold:    600;

// Line Heights
$lh-body:    1.8 !default;
$lh-heading: 1.3 !default;
$lh-lists:   1.5 !default;

// Misc
$site-nav-opacity:     0.97;
$site-nav-hover-delay: 0.25s !default;

$accent-color-prefix: 'accent-' !default;

$table-selector: 'table'; // The selector to use when targeting general body tables

$page-languages: 'en' 'es' 'fr' 'vi' 'zh';
