@use '../../../tools' as *;
@use '../../../common/structure/site-nav' as *;

.dk-site-nav {
  @include desktop {
    @include dk-site-nav-desktop($site-nav, $site-nav-current);
    @include dk-site-nav-desktop-sticky;
  }

  @include desktop-only {
    @include dk-site-nav-desktop-dropdown($site-nav-hover-delay);
  }

  @include mobile {
    @include dk-site-nav-mobile;
  }

  @include print {
    display: none;
  }
}
