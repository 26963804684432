@use 'sass:color';
@use 'sass:math';
@use '../../tools' as *;

.dk-pagination {
  margin: 2rem 0;
  font-family: $open-sans;

  @include row;

  .dk-per-page {
    display: inline-block;
    font-weight: 700;

    @include span-columns(6);

    select {
      font-family: $open-sans;
      font-size: 1.1em;
    }
  }

  .dk-pages {
    $button-size: 28px;
    display: block;
    text-align: right;
    cursor: default;
    font-size: 0;
    @extend %unselectable;

    %_page-button {
      border: {
        style: solid;
        width: 1px;
        radius: rem(2);
      }
      min-width: rem($button-size);
      height: rem($button-size);
      box-sizing: content-box;
    }

    & > * {
      display: inline-block;
      padding: 0;
      margin: 0 {
        left: rem(3);
      }
      height: rem($button-size);
      text-align: center;
      vertical-align: middle;
      color: $text-dark;
      font: {
        family: $open-sans;
        size: 1rem;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .gap {
      width: 0.8rem;
      line-height: rem($button-size) + math.div(1rem, 4);

      &::before {
        content: '\2026'; // Horizontal ellipsis
        display: inline;
      }
    }

    button {
      @extend %_page-button;
      border-color: $button-outline;
      background-color: $button-gray;

      &:hover, &:active {
        background-color: color.mix(#000, $button-gray, 10%);
        text-decoration: none;
        cursor: pointer;
      }

      .i-angle-left {
        position: relative;
        left: rem(-1);
      }
    }

    .current {
      @extend %_page-button;
      line-height: rem($button-size);
      color: $white;
      @extend %accent-border;
      @extend %accent-background;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        background: $button-gray;
        border: solid 1px $button-outline {
          radius: rem(2);
        }
        padding: 0;
        margin: 0;
        min-width: rem(30);
        text-align: center;

        & > * {
          display: block;
          padding: 0 rem(10);
        }

        a {
          color: $text-general;
        }
      }
    }
  }

  @include desktop {
    .dk-per-page + .dk-pages {
      @include span-columns(6);
    }
  }

  @include mobile {
    .dk-per-page, .dk-pages {
      text-align: center;

      @include span-columns(12);
    }

    .dk-pages {
      margin-top: 2rem;
    }
  }
}
