@use 'sass:color';
@use 'sass:math';
@use '../../../tools' as *;

// Audio player
.audio-pill {
  $c-stopped: #3563a2;
  $c-playing: #597e3e;
  $c-paused: #736214;
  $c-loading: #a3621e;
  $c-error: #bc4323;
  $colors: (
    stopped: $c-stopped,
    playing: $c-playing,
    paused: $c-paused,
    loading: $c-loading,
    error: $c-error
  );
  $height: 40;
  $width: 168;
  $width-wide: 240;
  $font-size: 14;
  $icon-size: $font-size + 2;

  display: inline-block;
  position: relative;
  width: rem($width);
  max-width: 100%;
  height: rem($height);
  border: 0;
  border-radius: rem(math.div($height, 2));
  padding: 0 rem(15) 0 rem(50);
  background-color: $c-stopped;
  color: $white;
  font: {
    family: $open-sans;
    weight: normal;
    size: rem($font-size);
  }
  line-height: math.div($height, $font-size);
  text-align: center;

  -webkit-appearance: none;

  &[data-counter] {
    width: rem($width-wide);
  }

  &:visited {
    color: $white;
  }

  &:hover {
    color: $white;
    text-decoration: none;
    background-color: color.adjust($c-stopped, $lightness: -10%);
    cursor: pointer;
  }

  @each $class, $color in $colors {
    &.#{$class} {
      background-color: $color;

      &:hover {
        background-color: color.adjust($color, $lightness: -10%);
      }
    }
  }

  &::before {
    content: $i-play;
    display: block;
    position: absolute;
    top: calc(50% - #{rem(math.div($icon-size, 2))});
    left: rem(math.div(40 - $icon-size, 2) + 3);
    width: rem($icon-size);
    height: rem($icon-size);
    font: {
      family: $dk-icons;
      size: rem($icon-size);
      weight: normal;
    }
    line-height: 1;
    text-align: center;
  }

  &.playing::before {
    content: $i-pause;
  }

  &.loading::before {
    @extend %content-svg-spinner;
  }

  &.error::before {
    content: $i-exclamation-circle;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    border-left: solid 1px $white;
    top: rem(5);
    bottom: rem(5);
    left: rem(41);
  }

  audio {
    display: none;
  }

  .times {
    font-size: 0.9em;
    margin-left: rem(5);

    &:empty {
      display: none;
    }

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }

  .progress {
    display: none;
    position: absolute;
    bottom: rem(5);
    left: rem(55);
    right: rem(20);
    height: rem(2);
    background-color: rgba($white, 0.3);

    & > div {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $white;
    }
  }

  &:is(.playing, .paused) {
    .progress {
      display: block;
    }
  }
}
