@use '../../tools' as *;

.stack-icon {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  font-style: normal;

  &::before, &::after {
    @include icon-base;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 50px;
  }

  &::before {
    content: $i-shape-square;
    @extend %accent-foreground;
  }

  &::after {
    content: $i-double-chevron;
    color: $white;
  }

  &.blank::after {
    font-size: 60px;
    top: -5px;
    left: -5px;
    @extend %accent-foreground;
  }

  @each $class, $val in $stack-shapes {
    &.#{$class}::before {
      content: $val;
    }
  }

  @each $class, $val in $stack-icons {
    &.#{$class}::after {
      content: $val;
    }
  }

  @each $name in get-accent-names() {
    &.#{$name} {
      --accent-fg-color: #{get-accent-color($name)};
    }
  }

  &.empty.black::before, &.blank.black::after {
    color: $black;
  }

  &.pentagon-arrow::after {
    left: -1px;
  }

  &.twitter.twitter.twitter {
    &::before {
      content: normal;
    }

    &::after {
      content: $i-twitter;
      color: $twitter-blue;
    }
  }

  &.facebook.facebook.facebook {
    &::before {
      content: normal;
    }

    &::after {
      content: $i-facebook-square;
      color: $facebook-blue;
    }
  }

  @include tablet {
    width: 42px;
    height: 42px;

    &::before, &::after {
      font-size: 42px;
    }

    &.blank::after {
      font-size: 50px;
      top: -4px;
      left: -4px;
    }
  }
}

.stack-icon-heading {
  display: flex;
  margin: 2rem 0 1rem;
  align-items: center;

  .stack-icon {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: rem(10);
  }

  & > * {
    margin-top: 0;
    margin-bottom: 0;
  }
}
