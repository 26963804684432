// General baseline element styling

@use 'sass:color';
@use 'variables' as *;
@use 'functions' as *;
@use 'mixins' as *;
@use 'utility';

@mixin enable-body-elements {
  @include headings {
    @extend %block-vmargin;
    @extend %font-heading;
  }

  @for $i from 1 through 6 {
    h#{$i}, .heading#{$i} {
      @extend %size-h#{$i};
    }
  }

  p {
    @extend %block-vmargin;
    @extend %dk-paragraph-text;
  }

  :is(ul, ol) {
    @extend %dk-list;
  }

  a {
    @extend %dk-link;
  }

  hr {
    margin: 2rem 0;
    border: solid 1px $divider-stroke;

    @extend %accent-border;
  }

  // Breakpoint-conditional line breaks
  br {
    &:is(.mobile, .m, .tablet, .t, .desktop, .d, .full, .f) {
      display: none;
    }

    @include mobile {
      &:is(.mobile, .m) {
        display: inline;
      }
    }

    @include tablet {
      &:is(.tablet, .t, .full, .f) {
        display: inline;
      }
    }

    @include desktop-only {
      &:is(.desktop, .d, .full, .f) {
        display: inline;
      }
    }
  }

  svg a {
    cursor: pointer;
  }
}

%block-vmargin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

%size-h1 {
  font-size: rem(30);

  @include mobile {
    font-size: rem(28);
  }
}
%size-h2 { font-size: rem(26); }
%size-h3 { font-size: rem(21); }
%size-h4 { font-size: rem(18); }
%size-h5 { font-size: rem(15); }
%size-h6 { font-size: rem(14); }
%font-heading {
  font-family: $font-sans;
  font-weight: $semibold;
  line-height: 1.3;
}

@for $i from 1 through 6 {
  %h#{$i} {
    @extend %block-vmargin;
    @extend %font-heading;
    @extend %size-h#{$i};
  }
}

%dk-intro-text {
  margin: 1rem 0;
  font-family: $font-serif;
  font-weight: bold;
  line-height: $lh-heading;
  color: $text-general;
  @extend %size-h3;
}

// Block elements
%dk-paragraph-text {
  font-size: 1rem;
  line-height: $lh-body;
}

%dk-paragraph, %p {
  @extend %block-vmargin;
  @extend %dk-paragraph-text;
}

@each $var in (disc, circle, square, decimal, lower-alpha, lower-roman) {
  %_list-style-#{$var} {
    list-style-type: $var;
  }
}

%dk-list {
  @extend %block-vmargin;
  padding: 0;
  font-size: 1rem;
  line-height: $lh-lists;

  li {
    @extend %remove-bookend-margins;
    list-style-position: outside;

    &, & > :is(ul, ol) {
      margin: 0.5em 0 0.5em 1.3em;

      @include desktop {
        margin-block: 0.3em;
      }
    }

    & > p {
      @extend %remove-bookend-margins;
      margin: 0.3em 0;
      line-height: inherit;
    }
  }

  ol { @extend %_list-style-lower-alpha; }
  ol ol { @extend %_list-style-lower-roman; }
}

%reset-nested-list-styles {
  & > li {
    ul { @extend %_list-style-disc; }
    :is(ul, ol) ul { @extend %_list-style-circle; }
    :is(ul, ol) :is(ul, ol) ul { @extend %_list-style-square; }
    ol { @extend %_list-style-decimal; }
    :is(ul, ol) ol { @extend %_list-style-lower-alpha; }
    :is(ul, ol) :is(ul, ol) ol { @extend %_list-style-lower-roman; }
  }
}

// Inline elements

%dk-link-standard {
  text-decoration: none;
  color: $link-standard;
}

%dk-link-visited {
  color: $link-visited;
}

%dk-link-hover {
  color: $link-hover;
  text-decoration: underline;
}

%dk-link {
  @extend %dk-link-standard;

  &:visited {
    @extend %dk-link-visited;
  }

  &:hover, &:active {
    @extend %dk-link-hover;
  }

  // Force unvisited appearance
  &.unvisited {
    &, &:visited {
      @extend %dk-link-standard;
    }

    &:hover {
      @extend %dk-link-hover;
    }
  }

  // Force visited appearance
  &.visited {
    @extend %dk-link-visited;
  }

  // Overflow long links with an ellipsis
  &.url-ellipsis {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  // Overflow long links with a line break
  &.url-break {
    word-wrap: break-word;
    word-break: break-all;
  }
}

%button {
  display: inline-block;
  font-size: 1rem;
  margin: 0.8rem 0;
  color: $white;
  text-decoration: none;
  font-family: $open-sans;
  background-color: $blue;
  padding: 0.5rem 2rem;
  border: 1px solid $blue;
  border-radius: rem(3);

  &:visited {
    color: $white;
  }

  &:hover {
    border-color: $indigo;
    background-color: $indigo;
    color: $white;
    text-decoration: none;
  }

  &.angle-right {
    position: relative;
    padding-right: 2.5rem;

    &:after {
      position: absolute;
      top: calc(50% - .6rem);
      right: 1.5rem;
      margin: 0;
    }
  }

  &.white-fill {
    color: $blue;
    background: $white;
    border: 1px solid $blue;

    &:hover, &.active {
      background: rgba($blue, 0.1);
    }
  }
}

%button-ghost {
  display: inline-block;
  color: $white;
  text-decoration: none;
  font-family: $open-sans;
  font-size: 1rem;
  line-height: 1;
  background: rgba($black, 0.15);
  padding: 1rem 3rem;
  border: 1px solid $white {
    radius: rem(3);
  }

  &.narrow-pad {
    padding: 1rem;
  }

  &:visited {
    color: $white;
    text-decoration: none;
  }
  &:hover, &:active {
    color: $white;
    background: rgba($black, 0.25);
    text-decoration: none;
  }

  &::after {
    @extend %angle-right-after;
    margin-top: rem(-2);
  }
}

%button-row {
  margin: 1rem 0;

  %button, %button-ghost {
    margin: 0.5rem 1rem 0.5rem 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

%standard-search-box {
  display: table;
  border-collapse: collapse;
  width: 100%;
  font-size: 0;

  & > * {
    font-family: $montserrat;
    color: $button-text;
  }

  & > button, & > input {
    border: 1px solid $button-outline;
    height: 2.5rem;
    font-size: rem(12.5);
    vertical-align: top;
  }

  button {
    width: rem(60);
    background: $button-gray;
    cursor: pointer;
    padding: 0;
    border: {
      left: 0;
      radius: 0 rem(3) rem(3) 0;
    }

    &:hover {
      background: color.mix(#000, $button-gray, 5%);
    }

    &:active {
      background: color.mix(#000, $button-gray, 10%);
    }
  }

  input {
    width: calc(100% - #{rem(60)});
    padding: 0 1em;
    border-radius: rem(3) 0 0 rem(3);
    appearance: none;

    &::placeholder {
      color: color.mix(#fff, $button-text, 25%);
    }
  }
}
