@use 'sass:color';
@use '../../../tools' as *;

.dk-main-container {
  .dk-blog-rail .subscribe {
    $height: 110;
    $max-width: 300;

    position: absolute;
    display: none;
    right: rem(-8);
    z-index: 25;
    height: rem($height + 8);
    width: rem(20);

    @include mobile {
      right: 0;
      z-index: 45;
    }

    & > :first-child {
      display: block;
      position: absolute;
      top: rem($height + 8);
      right: 0;
      z-index: 2;
      width: rem($height);
      height: rem(20);
      margin: 0;
      border: 0;
      color: $white;
      font: {
        weight: $semibold;
        size: rem(12);
        family: $open-sans;
      }
      line-height: rem(20);
      letter-spacing: 1px;
      box-shadow: 1px -1px rem(5) $medium-gray;
      transform: rotate(90deg) {
        origin: 100% 0;
      }
      text: {
        transform: uppercase;
        align: center;
      }
      @extend %unselectable;
      @extend %accent-background;

      @include desktop {
        border-radius: 0 rem(5) 0 0;

        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          height: rem(8);
          z-index: 5;
          background-color: inherit;
        }

        &::before {
          left: rem(-4);
          width: rem(4);
        }

        &::after {
          left: rem(-8);
          width: rem(8);
          border-radius: rem(5) rem(5) 0 0;
          background-image: linear-gradient(50deg, rgba(#000, 15%), rgba(#000, 45%));
        }
      }
    }

    & > a:first-child {
      &:visited {
        color: $white;
      }

      &:hover, &:active {
        text-decoration: none;
      }
    }

    &.opened .tray {
      max-width: rem($max-width + 12);
      transition: max-width ease-in-out 500ms;
    }

    .tray {
      position: absolute;
      top: rem(8);
      right: rem(8);
      z-index: 1;
      height: rem($height);
      max-height: rem($height);
      max-width: 0;
      overflow: hidden;
      background-color: $white;
      border: {
        style: solid;
        radius: rem(5) 0 0 rem(5);
        width: 2px 0 2px 2px;
      }
      transition: max-width ease-in-out 250ms;
      box-shadow: -1px 1px rem(5) $light-gray;
      @extend %accent-border;

      &:focus {
        outline: none;
      }

      & > * {
        display: table;
        height: rem($height);
        padding: rem(10) {
          right: rem(22)
        };

        @include mobile {
          width: rem($max-width);
        }

        & > form {
          display: table-cell;
          vertical-align: middle;
          background-color: $white;
        }
      }

      form {
        & > div {
          @include row;

          & > * {
            display: block;
            float: left;
            border: 1px solid $button-outline;
            font-family: $montserrat;
            font-size: rem(12.5);
            color: $text-general;
          }

          input {
            width: calc(100% - #{rem(60)});
            padding: 0.75rem 1rem;
            border-radius: rem(3) 0 0 rem(3);
            -webkit-appearance: none;

            &::placeholder {
              color: color.mix(#fff, $button-text, 25%);
            }

            &:focus {
              outline: none;
            }
          }

          button {
            width: rem(60);
            padding: 0.75rem rem(3);
            border: {
              left: 0;
              radius: 0 rem(3) rem(3) 0;
            }
            background: $button-gray;

            &:hover {
              background: color.mix(#000, $button-gray, 10%);
              cursor: pointer;
            }
          }
        }

        p {
          margin: 0 0 0.5rem;
          font-family: $open-sans;
          line-height: 1.4;
        }
      }

      a {
        display: block;
        font-family: $open-sans;
        text-decoration: none;
        color: $link-standard;
        line-height: rem(35);

        i {
          margin-right: rem(5);
        }

        &:hover {
          text-decoration: underline;
          color: $link-hover;
        }
      }
    }
  }
}
