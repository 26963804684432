@use "sass:string";

$icomoon-font-family: "dki" !default;
$icomoon-font-path: "fonts" !default;

$i-arrow-left: string.unquote('"\\e001"');
$i-arrow-right: string.unquote('"\\e000"');
$i-arrow-up: string.unquote('"\\e003"');
$i-arrow-down: string.unquote('"\\e002"');
$i-chevron-left: string.unquote('"\\e035"');
$i-chevron-right: string.unquote('"\\e036"');
$i-chevron-up: string.unquote('"\\e037"');
$i-chevron-down: string.unquote('"\\e038"');
$i-triangle-left: string.unquote('"\\e03f"');
$i-triangle-right: string.unquote('"\\e040"');
$i-triangle-up: string.unquote('"\\e041"');
$i-triangle-down: string.unquote('"\\e03e"');
$i-play: string.unquote('"\\e06a"');
$i-pause: string.unquote('"\\e06c"');
$i-stop: string.unquote('"\\e06d"');
$i-exit: string.unquote('"\\e03a"');
$i-exit-nih: string.unquote('"\\e03b"');
$i-top-en: string.unquote('"\\e03c"');
$i-top-es: string.unquote('"\\e03d"');
$i-menu: string.unquote('"\\e011"');
$i-share: string.unquote('"\\e039"');
$i-address-book: string.unquote('"\\e07b"');
$i-blog: string.unquote('"\\e07a"');
$i-mobile: string.unquote('"\\e022"');
$i-video: string.unquote('"\\e01d"');
$i-audio: string.unquote('"\\e042"');
$i-shape-circle: string.unquote('"\\e048"');
$i-shape-hexagon: string.unquote('"\\e04e"');
$i-shape-pentagon-arrow: string.unquote('"\\e04f"');
$i-shape-square: string.unquote('"\\e050"');
$i-google-scholar-square: string.unquote('"\\e918"');
$i-orcid-square: string.unquote('"\\e919"');
$i-researchgate-square: string.unquote('"\\e900"');
$i-researchgate: string.unquote('"\\e901"');
$i-cells: string.unquote('"\\e051"');
$i-collaboration: string.unquote('"\\e052"');
$i-double-chevron: string.unquote('"\\e053"');
$i-examine-report: string.unquote('"\\e054"');
$i-funding: string.unquote('"\\e055"');
$i-grant-apply: string.unquote('"\\e056"');
$i-grant-award: string.unquote('"\\e057"');
$i-grant-examine: string.unquote('"\\e058"');
$i-grant-fund: string.unquote('"\\e059"');
$i-grant-funding: string.unquote('"\\e05a"');
$i-grant-manage: string.unquote('"\\e05b"');
$i-grant-review: string.unquote('"\\e05c"');
$i-handshake: string.unquote('"\\e05d"');
$i-node-cluster: string.unquote('"\\e05e"');
$i-newspaper: string.unquote('"\\e05f"');
$i-new-investigator: string.unquote('"\\e060"');
$i-mortarboard: string.unquote('"\\e061"');
$i-medical-microscope: string.unquote('"\\e062"');
$i-lightbulb: string.unquote('"\\e063"');
$i-id-badge: string.unquote('"\\e064"');
$i-person-presenting: string.unquote('"\\e066"');
$i-small-business: string.unquote('"\\e067"');
$i-tools: string.unquote('"\\e068"');
$i-dna: string.unquote('"\\e045"');
$i-test-tubes: string.unquote('"\\e047"');
$i-robotic-arm: string.unquote('"\\e04d"');
$i-clipboard: string.unquote('"\\e046"');
$i-molecule: string.unquote('"\\e04a"');
$i-mouse: string.unquote('"\\e04c"');
$i-mortar-pestle: string.unquote('"\\e049"');
$i-people-discussing: string.unquote('"\\e065"');
$i-additional-reading: string.unquote('"\\e06e"');
$i-toolbox: string.unquote('"\\e06f"');
$i-weight-management: string.unquote('"\\e070"');
$i-school-responsibilities: string.unquote('"\\e071"');
$i-risk-factors: string.unquote('"\\e072"');
$i-checklists: string.unquote('"\\e073"');
$i-blood-screening: string.unquote('"\\e074"');
$i-diabetes: string.unquote('"\\e075"');
$i-download-file: string.unquote('"\\e076"');
$i-kidney-disease: string.unquote('"\\e077"');
$i-clinical-trials: string.unquote('"\\e902"');
$i-publications: string.unquote('"\\e903"');
$i-research-materials: string.unquote('"\\e904"');
$i-repository: string.unquote('"\\e913"');
$i-lab-software: string.unquote('"\\e91a"');
$i-lab-servers: string.unquote('"\\e91b"');
$i-pulse-programs: string.unquote('"\\e91c"');
$i-standards: string.unquote('"\\e91e"');
$i-webinar-s: string.unquote('"\\e921"');
$i-pos-block-left: string.unquote('"\\e905"');
$i-pos-block-center: string.unquote('"\\e906"');
$i-pos-float-left: string.unquote('"\\e907"');
$i-pos-float-right: string.unquote('"\\e908"');
$i-size-small: string.unquote('"\\e909"');
$i-size-medium: string.unquote('"\\e90a"');
$i-size-large: string.unquote('"\\e90b"');
$i-size-fill: string.unquote('"\\e90c"');
$i-webinar: string.unquote('"\\e911"');
$i-chat-bubble: string.unquote('"\\e912"');
$i-live-chat-o: string.unquote('"\\e914"');
$i-live-chat: string.unquote('"\\e915"');
$i-facebook-c: string.unquote('"\\e90d"');
$i-twitter-c: string.unquote('"\\e90e"');
$i-youtube-c: string.unquote('"\\e90f"');
$i-instagram: string.unquote('"\\e910"');
$i-linkedin-c: string.unquote('"\\e91f"');
$i-x-social-c: string.unquote('"\\e920"');
$i-quote-open: string.unquote('"\\e924"');
$i-quote-close: string.unquote('"\\e925"');
$i-copy: string.unquote('"\\e916"');
$i-angle-left: string.unquote('"\\e029"');
$i-angle-right: string.unquote('"\\e006"');
$i-angle-up: string.unquote('"\\e007"');
$i-angle-down: string.unquote('"\\e005"');
$i-angle-double-left: string.unquote('"\\e009"');
$i-angle-double-right: string.unquote('"\\e00a"');
$i-angle-double-up: string.unquote('"\\e00b"');
$i-angle-double-down: string.unquote('"\\e008"');
$i-thick-left: string.unquote('"\\e02f"');
$i-thick-right: string.unquote('"\\e030"');
$i-thick-up: string.unquote('"\\e031"');
$i-thick-down: string.unquote('"\\e02d"');
$i-plus: string.unquote('"\\e01a"');
$i-minus: string.unquote('"\\e019"');
$i-times: string.unquote('"\\e012"');
$i-download: string.unquote('"\\e034"');
$i-print: string.unquote('"\\e025"');
$i-spinner: string.unquote('"\\e06b"');
$i-check: string.unquote('"\\e043"');
$i-exclamation-circle: string.unquote('"\\e044"');
$i-question-circle: string.unquote('"\\e917"');
$i-search: string.unquote('"\\e00f"');
$i-search-plus: string.unquote('"\\e00e"');
$i-search-minus: string.unquote('"\\e010"');
$i-clock-o: string.unquote('"\\e020"');
$i-map-marker: string.unquote('"\\e023"');
$i-calendar: string.unquote('"\\e079"');
$i-calendar-o: string.unquote('"\\e078"');
$i-tty: string.unquote('"\\e021"');
$i-envelope: string.unquote('"\\e004"');
$i-envelope-o: string.unquote('"\\e02a"');
$i-facebook: string.unquote('"\\e01c"');
$i-x-social: string.unquote('"\\e922"');
$i-twitter: string.unquote('"\\e02b"');
$i-linkedin: string.unquote('"\\f0e1"');
$i-rss: string.unquote('"\\e024"');
$i-envelope-square: string.unquote('"\\e027"');
$i-facebook-square: string.unquote('"\\e01b"');
$i-twitter-square: string.unquote('"\\e01e"');
$i-x-social-square: string.unquote('"\\e923"');
$i-linkedin-square: string.unquote('"\\e07c"');
$i-rss-square: string.unquote('"\\e026"');
$i-phone-square: string.unquote('"\\e91d"');
$i-user-medical: string.unquote('"\\e033"');
$i-gear: string.unquote('"\\e032"');
$i-whatsapp: string.unquote('"\\f232"');
$i-reddit: string.unquote('"\\f281"');
$i-pinterest: string.unquote('"\\f231"');

