@use 'sass:color';
@use '../../tools' as *;

.column-blurbs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 2rem 0;

  & > div {
    display: flex;
    flex-direction: column;
    flex: 1 0 40%;
    max-width: 50%;

    &:only-child, &:nth-child(odd):not(:first-child) {
      margin-inline: auto;
    }

    @include mobile {
      flex-basis: 100%;
      max-width: 100%;
    }

    & > img {
      align-self: center;
      width: rem(180);
      height: rem(180);
      border-radius: 50%;
    }

    & > :is(span, a) {
      margin: 1rem 0 0.75rem;
      font-family: $open-sans;
      font-size: 1.25rem;
      font-weight: bold;
      text-align: center;
    }

    & > div {
      @extend %remove-child-bookend-margins;
    }

    & > p {
      margin: 0;
    }
  }
}
