// General/catch-all styles for miscellaneous components and structure (non-content)

@use 'sass:math';
@use '../../../tools' as *;

// News type tags (News Archive, Research Update pages)
.news-type {
  @extend %dk-tag;
  min-width: rem(110);
  padding: rem(2) rem(5);
  font-size: 12px;

  &.news-release {
    background-color: #204c8a;
  }

  &.research-update {
    background-color: #ac640c;
  }
}

// News dateline (Research Update pages)
.news-dateline {
  font-family: $open-sans;

  .news-type {
    margin-right: rem(10);
  }
}

// TODO: eval usage; possibly unnecessary
.link-tiles {
  margin: 1rem 0;
  border-color: $default-accent-color;
  list-style: none;
  font-weight: $semibold;
  font-family: $open-sans;
  @extend %themable-border;

  &.accented > * {
    border-top-width: rem(8);
  }

  & > * {
    display: block;
    margin: 1rem 0;
    border: solid 1px;
    border-color: inherit;
  }

  a {
    display: block;
    padding: 2rem 0.5rem;
    text-align: center;
  }

  @include desktop {
    display: flex;
    margin-bottom: 0;
    flex-flow: row wrap;
    justify-content: space-between;

    &.link-tiles > * {
      margin: 0 1rem 1rem 0;
      width: calc(#{math.percentage(math.div(1, 3))} - #{math.div(2, 3)}rem - #{rem(1)});
    }

    & > :nth-child(3n) {
      margin-right: 0;
    }

    a {
      width: 100%;
      padding: 0;

      @include ratio(8, 5);

      & > span {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        margin: 0;
        padding: 0.25rem;
        transform: translateY(-50%);
        text-align: center;
      }
    }

    &::after {
      content: '';
      display: block;
      width: calc(#{math.percentage(math.div(1, 3))} - #{math.div(2, 3)}rem - #{rem(1)});
    }
  }
}

// Facebook embed
.embed.facebook {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: visible;
}
