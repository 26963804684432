@use '../../../tools' as *;

.dk-language-dropdown {
  $outline-color: #cfcfcf;
  position: relative;
  display: block;

  @include dropdown-generic;
  @include span-columns(2 of 12);
  @include omega;

  @include desktop {
    flex: 1;
    align-self: flex-end;
    margin-left: rem(5);
  }

  @include mobile {
    float: right;
    padding-right: rem(10);
    width: 35%;
  }

  button[name=options] {
    width: 100%;
    height: 2.5rem;
    border: 1px solid $outline-color;
    border-radius: rem(3);
    font-weight: $semibold;
    font-size: rem(14);
    background-color: $white;

    span {
      display: block;
      position: relative;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;

      &::after {
        @extend %icon-base;
        content: $i-angle-down;
        display: block;
        position: absolute;
        right: -3px;
        top: 50%;
        width: rem(10) + 1rem;
        transform: translateY(-50%);
        padding: rem(5) 0 rem(5) rem(10);
        color: $link-standard;
        background-image: linear-gradient(to right, #{rgba($white, 0)}, $white 50%);
      }
    }
  }

  ul {
    left: rem(-7);
    right: rem(-7);
    top: calc(100% + #{rem(4)});
    border-color: $outline-color;

    @include mobile {
      left: rem(-7);
      right: rem(3);
    }

    li {
      margin: 0;
      padding: 0;
      border-color: $outline-color;

      & > * {
        display: block;
        padding: 0.6rem {
          right: 7rem;
        }
      }
    }

    &::before {
      right: rem(5);
      left: auto;
    }

    a {
      text-decoration: none;
      color: $button-text;
    }
  }
}
