@use 'sass:list';
@use '../../tools' as *;

$_alert-colors: (
  info:    #e7f6f8 #00bde3 $i-exclamation-circle 180deg,
  warning: #faf3d1 #ffbe2e $i-exclamation-circle 0deg,
  error:   #f4e3db #d54309 $i-times 0deg,
  success: #ecf3ec #00a91c $i-check 0deg
);

%_typed-alert {
  padding-left: 4rem;

  &:not(.no-icon)::before {
    @extend %icon-base;
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    font-size: 2rem;
  }
}

.alert {
  position: relative;
  margin: 1rem 0;
  padding: 1rem;
  padding-left: 1.8rem;
  border-left: 0.5rem solid #adadad;
  background-color: #f0f0f0;
  font-family: $open-sans;

  p {
    line-height: $lh-lists;
  }

  @each $cls, $data in $_alert-colors {
    $rotation: list.nth($data, 4);

    &.#{$cls} {
      @extend %_typed-alert;

      background-color: list.nth($data, 1);
      border-left-color: list.nth($data, 2);

      &:not(.no-icon)::before {
        content: list.nth($data, 3);
        @if $rotation != 0deg {
          transform: rotate($rotation);
        }
      }
    }
  }

  @extend %remove-child-bookend-margins;
}

