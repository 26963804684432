@use 'sass:color';
@use 'sass:math';
@use '../../tools' as *;

.dk-flex-table {
  margin: 0 rem(-10);
}

.dk-content .dk-flex-table {
  @include mobile {
    margin: 0 rem(-3) 0 rem(-16);
  }
}

.dk-row {
  $flex-box-gutter: 0.4em;
  $margin: rem(10);

  position: relative;
  z-index: 0; // Need new stacking context
  width: 100%;
  font-family: $merriweather;
  font-weight: normal;

  @include mobile {
    display: block;
    @include row;

    .dk-box {
      display: block;
      float: left;
    }
  }

  .dk-box {
    display: block;
  }

  @include desktop(true) {
    display: flex;
    flex-wrap: wrap;

    .dk-box {
      flex: 1 1 0px;
    }

    .dk-two-thirds {
      flex: 2 2;
    }

    .dk-one-third {
      flex: 1 1;
    }

    .dk-half {
      flex: 2 2;
    }

    .dk-one-quarter {
      flex: 1 1;
    }

    :is(.dk-two-thirds, .dk-one-third, .dk-half, .dk-one-quarter) {
      &.border-thick {
        flex-basis: rem(40);
      }

      &.border-thin {
        flex-basis: rem(22);

        &:last-child {
          flex-basis: rem(2);
        }
      }
    }
  }

  .dk-box .dk-box-content {
    width: 100%;
    padding: rem(20);
    font-family: $open-sans;

    @extend %remove-child-bookend-margins;

    & > .dk-box-content {
      padding: 0;
    }

    p {
      color: $text-general;
      line-height: 1.5;
      //overflow: hidden;
    }

    :is(ul, ol) {
      line-height: 1.3;
    }

    img {
      display: block;
      margin: 0 auto 1rem;
      border: 0;
    }

    .image-beside-link {
      margin: rem(-15);

      img {
        margin: 0;
      }
    }

    :is(figure, .figure) {
      margin: 1rem 0;

      img {
        margin: 0 auto;
      }

      @include mobile {
        margin: auto;
      }

      &.left {
        margin: 0 1rem 0 0;
      }

      &.right {
        margin: 0 0 0 1rem;
      }

      &:is(.left, .right) {
        & + p {
          margin-top: 0;
        }

        @include mobile {
          margin: 0 0 1rem;
        }
      }
    }

    @include desktop {
      & > figure.large, & > .figure.large {
        max-width: 49%;
      }
    }
  }

  .image-box {
    flex: 0 0 rem(200);

    figure {
      margin: 0 auto;
    }
  }
}

%thick-colored-dk-box {
  h2 {
    position: relative;
    margin: 0;
    padding: 1rem rem(18);
    border: 0;
    color: $white;

    a {
      color: inherit;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.dk-box {
  $base-background-color: $white !default;
  $base-font-color: $text-general !default;
  $thin-accent-border-width: rem(8) !default;
  $margin: rem(20);

  background: $white;
  text-decoration: none;

  &[class*="border-"] {
    @include desktop {
      &:not(:last-child) {
        margin-right: $margin;
      }
    }

    @include mobile {
      margin: $margin;
    }
  }

  &.border-thick {
    @include desktop {
      margin-bottom: $margin;
      margin-top: $margin;

      &:not(:last-child) {
        margin-right: $margin * 1.5;
      }
    }

    @include mobile {
      margin: $margin;
      width: calc(100% - #{2 * $margin});
    }

    @include print {
      margin: math.div($margin, 2) 0;

      &:not(:last-child) {
        margin-right: $margin;
      }
    }
  }

  &%thin-colored-dk-box {
    border-width: 1px;
    border-style: solid;

    @include desktop {
      margin-bottom: math.div($margin, 2);
      margin-top: math.div($margin, 2);
    }

    @extend %accent-border;
  }

  &.border-thin {
    @extend %thin-colored-dk-box;
  }

  &.border-thin-accent {
    @extend %thin-colored-dk-box;
    border-top-width: $thin-accent-border-width;
  }

  h2 {
    border-bottom: 1px solid $divider-stroke;
    padding: rem(14) 0;
    margin: 0 rem(14);
    color: $text-dark;
    text-align: left;
    font: {
      family: $merriweather;
      weight: normal;
    }
    @extend %size-h3;
  }

  &.dk-box {
    & > img, & > a > img {
      display: block;
      padding: rem(10);
      margin: 0 auto;

      &.left {
        max-width: 50%;

        @include mobile {
          float: left;
        }
      }
    }
  }

  & > a:first-child > img, & > img:first-child {
    padding-bottom: 0;
  }

  & > h2 + a > img, & > h2 + img {
    padding-top: 0;

    &.left {
      padding-bottom: 0;
    }
  }

  &.border-thick {
    @include with-accent-class {
      @extend %thick-colored-dk-box;

      h2 {
        @extend %accent-background;

        & > a:hover {
          text-decoration: none;
        }
      }
    }
  }

  &.fg-white {
    h2, h2 a, .dk-box-content, .dk-box-content p, .dk-box-content a, a:after, a:visited, time {
      color: $white;
    }

    :is(h2, .dk-box-content) a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }

      &:is(.cta, .dk-cta, .button, .button-ghost)::after {
        @extend %icon-hover-fix;
      }

      &:is(.button, .button-ghost) {
        text-decoration: none;
      }
    }
  }

  .dk-box-content {
    -webkit-hyphens: manual;
    hyphens: manual;
    word-break: break-word;

    @include row;

    @extend %remove-child-bookend-margins;
  }
}

// Feature boxes with thin borders should use narrower spacing
.dk-content {
  .promo-hero, .promo-banner {
    & + .dk-flex-table {
      margin-top: calc(-1 * (1rem - #{rem(5)}));
    }
  }

  .dk-flex-table {
    margin: 0;

    & + .action-link {
      margin: rem(5) 0;
    }

    & + .box-filled {
      margin: rem(5) 0;
    }
  }

  .action-link + .box-filled {
    margin-top: rem(10);
  }

  .dk-box.border-thin, .dk-box.border-thin-accent, .dk-box.image-box {
    margin-block: rem(5);
    margin-right: rem(10);

    &:last-child {
      margin-right: 0;
    }

    :is(h2, h3) {
      margin-inline: rem(10);
      border: 0;
      font-family: $open-sans;
      font-weight: bold;
      font-size: rem(18);
    }

    .dk-box-content {
      padding-inline: rem(10);
      font-family: $merriweather;
      color: $text-general;

      &:not(:first-child) {
        padding-top: 0;
      }
    }
  }

  @include mobile {
    .dk-row {
      margin-left: 0;
    }

    .dk-box.border-thin, .dk-box.border-thin-accent, .dk-box.image-box {
      width: 100%;
      margin-inline: 0;
    }
  }
}

// Thick border styling
.border-thick {
  $thickness: rem(10);
  $position: -1 * $thickness;
  $border-color: $button-gray;

  position: relative;
  margin: $thickness;
  box-shadow: 0 0 rem(5) rem(2) color.mix(#000, $border-color, 5.5%);

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    inset: $position;
    background: $border-color;
  }
}
