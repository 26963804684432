@use 'sass:color';
@use '../variables' as *;
@use '../functions' as *;
@use '../icons' as *;

@mixin dropdown-generic {
  & > * {
    font-family: $open-sans;
    color: $button-text;
  }

  button {
    cursor: pointer;
    background-color: $button-gray;
    border: 1px solid $button-outline;
  }

  ul {
    visibility: hidden;
    position: absolute;
    top: calc(100% + #{rem(6)});
    left: 0;
    z-index: 20;
    margin: 0;
    padding: 0;
    border: 1px solid $button-outline {
      radius: rem(5);
    }
    background-color: $white;
    list-style: none;
    font: {
      size: rem(13);
      family: $open-sans;
    }
    box-shadow: 0 rem(3.5) rem(12) rgba($black, 0.13);
    opacity: 0;
    transition: all 0.2s linear;

    &.shown {
      opacity: 1;
      visibility: visible;
      transition: all 0.2s linear;
    }

    li {
      border-top: 1px solid $button-outline;
      padding: 0.6rem {
        right: 7rem;
      }
      margin: 0;
      cursor: pointer;
      text-align: left;

      &:first-child {
        border-top: 0;
        border-radius: rem(4) rem(4) 0 0;
      }

      &:last-child {
        border-radius: 0 0 rem(4) rem(4);
      }

      &[aria-checked=true], &.current {
        background-color: color.mix(#000, $button-gray, 10%);
        font-weight: $semibold;

        &[aria-selected=true] {
          background-color: color.mix(#000, $button-gray, 20%);
        }
      }

      &[aria-selected=true] {
        background-color: $button-gray;
      }
    }

    &::before {
      display: block;
      position: absolute;
      top: rem(-19);
      left: rem(7);
      width: rem(30);
      height: rem(30);
      color: $white;
      content: $i-triangle-up;
      font: {
        size: rem(30);
        family: $dk-icons;
      }
      line-height: 1;
      text: {
        rendering: auto;
        shadow: 0 -1px 0 $button-outline;
      }
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @at-root .no-textshadow & {
        color: $divider-stroke;
      }
    }
  }
}
