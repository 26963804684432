// Deprecated styles/patterns that may no longer be needed
// TODO: evaluate need to retain

@use 'sass:color';
@use '../../../tools' as *;

// Former NKDEP social links
a.social-link {
  font-family: $open-sans;
  font-weight: $semibold;
  font-size: 1.1em;
  color: $text-general;

  &::after {
    display: inline-block;
    width: rem(24);
    height: rem(24);
    margin-left: rem(5);
    border-radius: rem(5);
    font-family: $dk-icons;
    font-size: rem(16);
    line-height: 1.6;
    font-weight: normal;
    text-align: center;
    vertical-align: top;
    @include icon-hover-fix;
  }

  &.twitter {
    &::after {
      content: $i-twitter;
      color: $white;
      background-color: $twitter-blue;
    }

    &:hover::after {
      background-color: color.adjust($twitter-blue, $lightness: -10%);
    }
  }

  &.facebook {
    &::after {
      content: $i-facebook;
      color: $white;
      background-color: $facebook-blue;
    }

    &:hover::after {
      background-color: color.adjust($facebook-blue, $lightness: -10%);
    }
  }

  & + a.social-link, & + .eld + a.social-link {
    margin-left: 1em;
  }

  & + .eld {
    display: none;
  }
}

// Former SCORM education module launch button
.scorm-launch {
  max-width: rem(330);
  margin: 2rem auto;

  @extend %remove-child-bookend-margins;

  .button.button {
    margin: 0;
    width: 100%;
    text-align: center;
    padding: {
      top: rem(10);
      bottom: rem(10);
    }

    @include mobile {
      background-color: #d2d0ca;
      color: $text-general;
      cursor: default;
    }
  }

  p {
    font-size: rem(12.5);
  }
}

// News with image for News landing "Advances in Health" box
// TODO: Eval if still needed (not on Prod)
.news-with-image {
  @include row;

  & > .image.image.image img {
    margin: 0;

    @include mobile {
      margin-bottom: 0.5rem;
    }
  }

  @include desktop {
    & > .image {
      margin-top: rem(2);

      @include span-columns(2);

      & + .news {
        @include span-columns(10);
      }
    }
  }

  & > .news {
    time {
      display: block;
      margin-bottom: rem(4);
    }

    .title {
      font-weight: bold;
    }

    p {
      margin: 0.5rem 0 0;
    }
  }
}
