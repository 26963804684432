@use 'sass:color';
@use 'sass:math';
@use 'sass:list';
@use '../../tools' as *;

// Ensure that page theming is enabled
@include enable-themable-pages;

%_accordion-wrap {
  margin: 2rem 0;

  @for $i from 1 through 5 {
    @at-root h#{$i} + & {
      margin-top: 1rem;
    }
  }
}

%_accordion-border {
  border-width: 0;
  border-style: solid;
  @extend %accent-border;
}

%_accordion-main {
  @extend %_accordion-border;
  border-width: 1px;
  background-color: $white;

  @extend %themable-border;
}

%_accordion-title {
  display: block;
  position: relative;
  margin: 0;
  padding: 0.9rem 3.5rem 0.9rem 1rem;
  background-color: transparent;
  font: bold #{rem(14)}/normal $open-sans;
  text-align: left;
  color: $text-dark;
}

%_accordion-title-clickable {
  &:hover {
    background-color: color.adjust($bg-gray, $lightness: -10%);
  }

  &::after {
    content: $i-arrow-down;
    display: block;
    position: absolute;
    top: 50%;
    right: 1rem;
    font: 2rem/1 $dk-icons;
    transform: translateY(-50%);
    @extend %accent-foreground;

    @extend %themable-forecolor;
  }
}

%_accordion-title-open {
  background-color: $bg-gray;

  &:hover {
    background-color: color.adjust($bg-gray, $lightness: -10%);
  }

  &::after {
    content: $i-arrow-up;
  }
}

%_accordion-basic-after-heading-margin {
  margin-top: 3rem;
}


// Accordion "convenience" markup (wrapping div.accordion around normal headings and content)
.accordion {
  @extend %_accordion-wrap;
  @extend %_accordion-main;
  padding: 0 1rem;

  &.accordion > * {
    display: none;
  }

  &:not([data-no-expand-all]):not(.off) {
    margin-top: 4rem;

    @for $i from 1 through 5 {
      @at-root h#{$i} + & {
        @extend %_accordion-basic-after-heading-margin;
      }
    }
  }

  @for $i from 2 through 6 {
    $sel: "h#{$i}:not(.literal):not([data-literal])";

    & > h#{$i}:first-child {
      border-top: 0;

      &, & ~ #{$sel} {
        @extend %_accordion-title;
        @extend %_accordion-border;
        border-top-width: 1px;
        margin: 0 -1rem;

        // Show targeted section (anchor link)
        &:target {
          background-color: $bg-gray;

          & ~ * {
            display: block;
          }

          & ~ #{$sel}:not(:target) ~ :not(#{$sel}) {
            display: none;
          }
        }

        // Unable to support multiple .open sections using the convenience markup
      }
    }
  }
}

%_accdn-title-open {
  button {
    @extend %_accordion-title-open;
  }

  & + .acc-content {
    display: block;
  }
}

.dk-accdn {
  @extend %_accordion-wrap;

  .acc-ctrl {
    text-align: right;
    font: 1rem/1.5rem $open-sans;

    button {
      color: $link-standard;

      &:hover {
        text-decoration: underline;
        color: $link-hover;
      }
    }
  }

  .acc-ctrl + .acc-main {
    margin-top: 0.5rem;
  }

  .acc-main {
    @extend %_accordion-main;
    background-color: $white;
  }

  .acc-title {
    margin: 0;
    @extend %_accordion-border;
    border-top-width: 1px;

    &:first-child {
      border: 0;
    }

    &.nobtn {
      @extend %_accordion-title;
      background-color: $bg-gray;

      & + .acc-content {
        display: block;
      }
    }

    &.open {
      @extend %_accdn-title-open;
    }

    button {
      @extend %_accordion-title;
      @extend %_accordion-title-clickable;
      width: 100%;
      outline-offset: rem(-4);
    }
  }

  &:not([data-ready]) .acc-title:target {
    @extend %_accdn-title-open;
  }

  .acc-content {
    display: none;
    padding: 1rem;
    @extend %remove-child-bookend-margins;
  }

  button {
    border: 0;
    padding: 0;
    background: transparent;
    appearance: none;
    text-align: left;
    font: inherit;
    cursor: pointer;
  }
}

// Hide legacy expand/collapse (in case any remain)
.dk-accordion-title { display: none; }
