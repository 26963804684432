@mixin headings($start: 1, $end: 6, $includeClasses: true) {
  @if $start < 1 or $start > 6 or $end < 1 or $end > 6 or $start > $end {
    @error "Invalid range: $start must be >= 1, <= $end, and <= 6. $end must be >= $start, >= 1, and <= 6.";
  }

  $hlevels: "";
  $hclasses: "";
  @for $i from $start through $end {
    @if $i > $start {
      $hlevels: "#{$hlevels}, ";
      $hclasses: "#{$hclasses}, ";
    }

    $hlevels: "#{$hlevels}h#{$i}";
    $hclasses: "#{$hclasses}.heading#{$i}";
  }

  $selector: ":is(#{$hlevels})";
  @if $includeClasses {
    $selector: "#{$selector}, :is(#{$hclasses})"
  }

  #{$selector} {
    @content;
  }
}
