@use '../../tools' as *;

.annotated-image-list {
  display: grid;
  gap: 2.5rem;
  margin-block: 2rem;

  & > * {
    margin: 0;
  }

  :is(div, p) {
    line-height: $lh-body;
  }

  div {
    @extend %remove-child-bookend-margins;
  }

  img {
    display: block;
    max-height: rem(300);
    margin-inline: auto;
    justify-self: center;
  }

  @include desktop(true) {
    grid-template-columns: #{rem(220)} 1fr;
    align-items: center;
  }
}

.annotated-image-list:is(ul,ol) > li {
  display: contents;

  & > * {
    margin: 0;
  }

  & > ul, & > div > ul {
    list-style: disc;
  }

  & > ol, & > div > ol {
    list-style: decimal;
  }
}
