@use '../../tools' as *;

// Dictionary terms
.dictionary-term {
  &, &:visited {
    color: $link-standard;
    text-decoration: none;
    border-bottom: 1px dashed $link-standard;
  }

  &:hover, &:active {
    color: $link-hover;
    text-decoration: none;
  }
}

// Term definition dictionary list
dl.term-definition {
  margin: 0 0 1rem;

  dfn {
    font-style: normal;
  }

  dt {
    @extend %size-h2;
    margin: 1rem 0;
    font-family: $open-sans;
    font-weight: $semibold;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    @extend %p;
    font-family: $merriweather;
    font-size: 1rem;
    margin: 1rem 0;

    &:last-child {
      margin-bottom: 0;
    }

    &.pronunciation {
      font-family: $open-sans;
    }
  }
}
