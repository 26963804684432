// Ensure floating elements are contained within their parent
@mixin clearfix {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

// Only display content to screen readers. A la Bootstrap 4.
//
// See: http://a11yproject.com/posts/how-to-hide-content/
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
//
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
//
// Credit: HTML5 Boilerplate
@mixin sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

$hispec-id: '#___hispec';
$hispec-class: '.___hispec';

// A bit of an anti-pattern, but this mixin forces a higher specificity for a set of styles
// to override dominant styling in complicated scenarios. The effect is similar to !important
// without the change in behavior that !important introduces. It should be nested within another
// selector, not used at the outermost level of a stylesheet. Avoid using at all if possible.
// @param $id: number - Number of ID selectors to add (default: 1)
// @param $class: number - Number of class selectors to add (default: 0)
@mixin boost-specificity($id: 1, $class: 0) {
  $_sel: '';

  @for $i from 0 to $id {
    $_sel: $_sel + $hispec-id;
  }

  @for $i from 0 to $class {
    $_sel: $_sel + $hispec-class;
  }

  &:is(#{$_sel}, *) {
    @content;
  }
}
