// NIDDK-specific miscellaneous content styles and patterns

@use 'sass:color';
@use 'sass:math';
@use '../../../tools' as *;

// Publications dictionary list
dl.publications {
  line-height: 1.5;

  dt {
    margin: 0.4rem 0 0;
    font-weight: bold;
  }

  dd {
    margin-left: 0;

    p {
      line-height: inherit;
      margin: 0;
    }
  }

  dd + dt {
    margin-top: 1.5rem;
  }

  &.accented {
    dd, dt {
      border-left: solid 5px $block-accent-gray;
      padding-left: 1rem;
    }
  }
}

// 60%/40% split on desktop (e.g., NMRI login)
@include desktop {
  .split-60-40 {
    display: grid;
    grid-template-columns: 60% 1fr; // Not exactly 3:2, but left column is 60% to match old flex implementation
    gap: 1.5rem;
    align-items: center;

    & > :nth-child(even) {
      justify-self: end;
    }
  }
}

// NMRI staff contact with photo
.contact-with-photo {
  display: grid;
  grid-template-columns: #{rem(100)} 1fr;
  gap: 1rem;
  align-items: center;
  margin: 1rem 0;

  img {
    display: block;
    max-width: rem(100);
    border-radius: 50%;
  }
}

// Person block (for Director's Update Commendations & Commencements)
.person-block {
  @extend %clearfix;
  margin: 1rem 0;

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  & + .person-block,
  & + p {
    padding-top: 1rem;
    border-top: solid 1px $divider-stroke;
  }
}

// Callout box with button (Training & Employment detail)
.callout-button {
  @extend %remove-child-bookend-margins;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  padding: 2rem;
  border-radius: rem(5);
  background-color: $bg-gray;

  & > * {
    text-align: center;
  }

  @include desktop(true) {
    min-width: rem(400);
    width: fit-content;
  }
}

// Research Programs & Contacts staff
.program-staff {
  @include row;
  @extend %thin-box;

  ul {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;

    li {
      display: block;
      list-style: none;
      margin: 1rem 0 0;
      padding: 0;

      &:nth-child(1) {
        margin-top: 0;
      }

      a:first-of-type {
        display: block;
      }

      @include desktop {
        width: calc(50% - 0.5rem);
        float: left;

        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(2n) {
          margin-left: 1rem;
        }

        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }
}

// Bootstrap-style "selling points" with small icon image and brief text (e.g., STEP-UP)
.selling-points {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 1rem;
  text-align: center;
  font-family: $open-sans;

  & > * {
    flex: 1 0 rem(160);

    & > :first-child {
      margin-bottom: 1rem;
    }

    & > * + * {
      margin-top: 0.5rem;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: rem(70);
  }
}

// Image "pop" hero component (e.g., STEP-UP)
.imagepop-hero {
  position: relative;
  padding-top: math.percentage(math.div(338, 660));
  overflow: hidden;

  .ih-grid {
    $imgWidth: math.percentage(math.div(302, 660));

    display: grid;
    position: absolute;
    grid-template: rem(8) #{1fr} / $imgWidth 1fr;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: $open-sans;
    font-weight: $semibold;

    & > * {
      background: transparent top left / cover no-repeat;
    }

    & > .bg-box {
      grid-area: 2 / 1 / span 1 / span 2;
      border-radius: rem(18);
    }

    & > .bg-img {
      grid-area: 1 / 1 / span 2 / span 1;
      padding-top: math.percentage(math.div(338, 302));
    }

    & > .content {
      grid-area: 2 / 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      @extend %remove-child-bookend-margins;

      & > * {
        margin: 0.5rem 0;
      }
    }

    .title {
      font-size: 1.3rem;
      font-size: clamp(1rem, 2vw, 1.5rem);
      line-height: 1.3;
    }

    a:not(.button) {
      color: $link-hover;
    }
  }

  @include mobile {
    padding: 0;

    .ih-grid {
      position: static;
      grid-template: #{rem(8)} auto auto / 1fr;

      & > .bg-box {
        grid-area: 2 / 1 / span 1 / span 1;
        border-radius: rem(18);
      }

      & > .bg-img {
        grid-area: 1 / 1 / span 2 / span 1;
        min-height: rem(160);
        padding-top: 50%;
        background-size: 60%;
        background-position: center top;
      }

      & > .content {
        grid-area: 3 / 1 / span 1 / span 1;
        margin: 1rem 0;
        padding: 0;
      }

      .title {
        font-size: 1.5rem;
      }
    }
  }
}

.imagepop-hero-note {
  font-family: $open-sans;
  font-weight: $semibold;

  @include desktop {
    text-align: right;
  }
}

// Social media link cards (e.g., Follow Us)
.social-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(#{rem(104)}, 1fr));
  gap: rem(10);
  margin: 1.5rem 0;
  font-weight: bold;
  font-family: $open-sans;

  &:not(.v2) {
    grid-template-columns: repeat(auto-fit, minmax(#{rem(154)}, 1fr));
    @extend %size-h4;
  }

  & > * {
    position: relative;
    overflow: hidden;
    border-radius: rem(3);
    box-shadow: $tile-shadow;

    img {
      display: block;
    }

    & > :last-child {
      display: block;
      padding: 0.5rem;
    }

    a:first-of-type:not(.eld)::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

// Stack of links with chevrons (e.g., Director box on News landing)
// TODO: eval usage; replace with .ladder-links?
.chevron-stack {
  display: block;
  list-style: none;

  & > li {
    display: block;
    position: relative;
    margin-left: 0;
    border: solid 1px $divider-stroke;
    border-width: 0 0 1px;
    padding: 0.5rem rem(24) 0.5rem rem(5);

    &:first-child {
      border-top-width: 1px;
    }

    &::after {
      @extend %icon-base;
      content: $i-angle-right;
      display: block;
      position: absolute;
      right: rem(5);
      top: 50%;
      transform: translateY(-50%);
      color: $link-standard;
      font-size: 1.2em;
    }
  }
}
