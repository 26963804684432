@use 'sass:math';
@use 'variables' as *;
@use 'functions' as *;
@use 'mixins' as *;
@use 'icons' as *;
@use 'accent-colors' as ac;
@use 'utility';

// Responsive images and figures

@mixin enable-images-figures {
  img {
    @extend %dk-image-sizing;
  }

  :is(img, figure, :where(.figure)) {
    @extend %dk-image-figure-positioning;
  }

  :is(figure, :where(.figure)) {
    @extend %dk-figure;
  }
}

%dk-image-sizing {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border: 0;

  &.center {
    display: block;
  }
}

%dk-image-figure-positioning {
  &:is(.left, .right, .center) {
    margin: 1rem auto;
  }

  @include desktop {
    float: none;
    clear: both;

    &.xsmall { max-width: 25%; }
    &.small { max-width: 50%; }
    &.medium { max-width: 75%; }
    &.large { max-width: 100%; }

    &:is(.left, .right) {
      max-width: 80%;

      &.xsmall { max-width: 10%; }
      &.small { max-width: 25%; }
      &.medium { max-width: 40%; }
      &.large { max-width: 75%; }
    }

    &.left {
      float: left;
      clear: left;
      margin: 0.5rem 1rem 0.5rem 0;
    }

    &.right {
      float: right;
      clear: right;
      margin: 0.5rem 0 0.5rem 1rem;
    }
  }
}

%dk-figure {
  display: table;
  position: relative;
  max-width: 100%;
  margin: 1rem 0;
  font-family: $open-sans;
  text-align: center;

  &.fill {
    width: 100%;

    img.fill {
      width: 100%;
    }
  }

  &.no-margin {
    margin: 0;
  }

  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }

  a.expand {
    $dflt-alpha30: rgba($default-accent-color, 0.3);

    display: block;
    position: absolute;
    top: rem(5);
    right: rem(5);
    width: rem(45);
    height: rem(45);
    border-radius: rem(5);
    background-color: $dflt-alpha30;
    background-color: var(--accent-alpha30-color, $dflt-alpha30);
    color: $black;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    transition: background-color linear 150ms;
    font-size: 0;

    &::before {
      @include icon-base;
      content: $i-search-plus;
      font-size: rem(24);
      line-height: math.div(45, 24);
      color: $black;
      transition: color linear 150ms;
    }

    & > * {
      display: none;
    }
  }

  &:hover a.expand, a.expand:focus {
    background-color: $default-accent-color;
    background-color: var(--accent-bg-color, #{$default-accent-color});
    transition: background-color linear 100ms;
    text-shadow: none;

    &::before {
      color: $white;
      transition: color linear 100ms;
    }
  }

  & > :is(figcaption, .figcaption, .caption, .credit) {
    display: table-caption;
    caption-side: bottom;
    min-width: rem(150);
    padding: 0.5rem;
    background-color: $bg-gray;

    &.white {
      background-color: $white;

      &:not(.pad) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  :is(figcaption, .figcaption, .caption, .credit) {
    text-align: left;

    &, & > * {
      line-height: 1.4;
    }

    @extend %remove-child-bookend-margins;
  }

  :is(figcaption, .figcaption) {
    :is(.caption, .credit) {
      display: block;

      &.hide {
        display: none;
      }
    }

    .credit {
      margin-top: -0.25rem;

      &:not(.hide) + .caption {
        margin-top: 0.5rem;
        padding-top: 0.5rem;
        border-top: solid 1px $divider-stroke;
      }
    }
  }

  .credit {
    font-size: 10.5px;
  }

  & > .credit {
    padding-top: 0.25rem;

    &.white {
      & + :is(figcaption, .figcaption, .caption) {
        background-color: $white;
        padding-top: 0;
      }

      &:not(.pad) + :is(figcaption, .figcaption, .caption) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &:not(.white) + :is(figcaption, .figcaption, .caption):not(.white) {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0.5rem;
        right: 0.5rem;
        border-top: solid 1px $divider-stroke;
      }
    }
  }

  &.youtube-figure {
    &, & > :is(figcaption, .figcaption, .caption, .credit) {
      display: block;
    }

    & > .expand {
      display: none;
    }

    .youtube-container {
      width: 100%;
    }

    iframe {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }
}
