@use 'sass:color';
@use '../../tools' as *;

.interrupter-survey {
  border-radius: rem(5);

  &:is(.interrupter-survey) {
    padding: 0.5rem;
  }

  .interrupter-survey-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 8.08rem;
  }

  header, .display-none {
    display: none;
  }

  .usa-alert__body {
    display: inline;
    color: $text-general;
    font-family: $open-sans;
    font-weight: $semibold;

    h2 {
      display: none;
    }
  }

  .touchpoints-yes-no-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > label {
      width: rem(300);
      margin: 0 0 1.5rem;
      font-weight: $semibold;
      text-align: center;
      line-height: 1.33;
      color: $text-general;
      font-family: $open-sans;
    }

    & > .submit-button {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;

      button[type=submit] {
        cursor: pointer;
        width: rem(100);
        padding: 0.5rem;
        border: 1px solid $button-outline;
        border-radius: rem(3);
        color: $text-general;
        font-weight: $semibold;
        text-transform: capitalize;
        background-color: $white;
        outline-offset: 4px;

        &:not(:last-child) {
          margin-right: 0.33rem;
        }

        &:hover, &:focus {
          border-color: color.adjust($color: $button-outline, $lightness: -15%);
          background-color: $button-gray;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .int-usa-banner {
    font-family: $montserrat;

    .usa-banner__inner {
      align-items: center;

      .usa-banner__header-flag {
        width: rem(16);
        margin-right: 0.5rem;
      }

      .usa-banner__header-text {
        font-size: 10.5px;
        line-height: 1.5;

        .usa-link--external {
          text-decoration: underline;
        }

        .usa-link--external:not(:hover), .i-exit {
          color: $link-standard;
        }

        .i-exit::before {
          font-size: 10.5px;
        }
      }
    }
  }

  .interrupter-usa-banner {
    font-family: $montserrat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAG1BMVEUdM7EeNLIeM7HgQCDaPh/bPh/bPx/////bPyBEby41AAAAUElEQVQI123MNw4CABDEwD3jC/9/MQ1BQrgeOSkIqYe2o2FZtthXgQLgbHVMZdlsfUQFQnHtjP1+8BUhBDKOqtmfot6ojqPzR7TjdU+f6vkED+IDPhTBcMAAAAAASUVORK5CYII=");
    background-size: rem(16);
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 1.65rem;
    font-size: 10.5px;
    line-height: 1.5;

    a {
      text-decoration: underline;

      &:not(:hover), .i-exit {
        color: $link-standard;
      }

      &.i-exit::before {
        font-size: 10.5px;
      }
    }
  }

  @include mobile {
    .touchpoints-yes-no-buttons {
      padding: 1.5rem 0;
    }

    .usa-alert__text {
      padding: 0 3rem;
      font-size: 1.25rem;
      text-align: center;
    }
  }

  @include desktop(true) {
    &:is(.interrupter-survey) {
      padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    }

    .interrupter-survey-content {
      min-height: 3rem;
      padding: 0.25rem 0 0.5rem;
    }

    .touchpoints-yes-no-buttons {
      flex-direction: row;
      justify-content: space-around;

      & > label {
        margin: 0;
        text-align: left;
      }
    }
  }
}

.fba-usa-modal {
  .touchpoints-form-wrapper:focus {
    outline: none;
  }

  .touchpoints-form-disclaimer > div {
    max-height: 5rem;
    margin: 0.5rem 0;
    line-height: 1;
    overflow-y: scroll;
    scrollbar-gutter: stable;
  }

  .usa-banner {
    border-radius: 0 0 0.5rem 0.5rem;

    .usa-banner__inner {
      padding: 0 1rem;
    }
  }

  &:not(:has(form div.fba-visible:first-child)) {
    .touchpoints-form-disclaimer {
      display: none;
    }
  }

  .question-options {
    .usa-radio:focus-within {
      outline: 2px solid;
      outline-offset: 1px;
      border-radius: rem(2.5);
    }
  }
}
