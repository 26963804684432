@use '../variables' as *;
@use '../functions' as *;
@use 'media-queries' as *;

@mixin text-scale($min-size, $max-size, $min-width, $max-width) {
  $smin: strip-unit($min-size);
  $smax: strip-unit($max-size);
  $wmin: strip-unit($min-width);
  $wmax: strip-unit($max-width);

  @if ($smin < 10) {
    $smin: 10;
  }

  font-size: rem($smin);

  @include text-scale-limited($min-size * $tablet-scale, $max-size * $tablet-scale, $tablet-min, $tablet-max);

  @include breakpoint($min: #{$wmax + 1}px) {
    font-size: rem($smax);
  }
}

@mixin text-scale-limited($min-size, $max-size, $min-width, $max-width) {
  $smin: strip-unit($min-size);
  $smax: strip-unit($max-size);
  $wmin: strip-unit($min-width);
  $wmax: strip-unit($max-width);

  @if ($smin < 10) {
    $smin: 10;
  }

  @include breakpoint($min: #{$wmin}px, $max: #{$wmax}px) {
    font-size: calc(#{$smin}px + (#{$smax} - #{$smin}) * ((100vw - #{$wmin}px) / (#{$wmax} - #{$wmin})));
  }
}

@mixin text-scale-mobile($min-size, $max-size) {
  @include text-scale($min-size, $max-size, 320px, $mobile-max);
}

@mixin text-scale-tablet-variable($min-size, $max-size) {
  $smin: strip-unit($min-size);
  $smax: strip-unit($max-size);

  @if ($smin < 10) {
    $smin: 10;
  }

  font-size: rem($smin);

  @include text-scale-limited($min-size * $tablet-scale, $max-size * $tablet-scale, $tablet-min, $tablet-max);

  @include desktop-only {
    font-size: rem($smax);
  }
}

@mixin text-scale-until-fixed($min-size, $max-size) {
  $smin: strip-unit($min-size);
  $smax: strip-unit($max-size);

  @if ($smin < 10) {
    $smin: 10;
  }

  font-size: rem($smin);

  @include text-scale-limited($min-size, $max-size, 320px, $mobile-max);
  @include text-scale-limited($min-size * $tablet-scale, $max-size * $tablet-scale, $tablet-min, $variable-layout-max);

  @include breakpoint($min: $fixed-layout-min) {
    font-size: rem($smax);
  }
}

@mixin text-scale-mobile-tablet($min-size, $max-size) {
  $smin: strip-unit($min-size);
  $smax: strip-unit($max-size);

  @if ($smin < 10) {
    $smin: 10;
  }

  font-size: rem($smin);

  @include text-scale-limited($min-size, $max-size, 320px, $mobile-max);
  @include text-scale-limited($min-size * $tablet-scale, $max-size * $tablet-scale, $tablet-min, $tablet-max);

  @include desktop-only {
    font-size: rem($smax);
  }
}
