// Button styles

@use 'sass:color';
@use '../../tools' as *;

a.button {
  @extend %button;

  &.angle-left::before {
    @include icon-base;
    content: $i-angle-left;
    margin-top: rem(-2);
    padding-right: 0.5em;
    font-size: 1.2em;
    vertical-align: middle;
  }

  &.gray {
    background-color: $button-gray;
    color: $button-text;
    border: 1px solid $button-outline;

    &:visited {
      color: $button-text;
    }

    &:hover {
      background: color.mix(#000, $button-gray, 5%);
      color: $button-text;
      text-decoration: none;
    }
  }

  &.blue-green {
    background-color: $bg-blue-green;
    border-color: $bg-blue-green;

    &:hover {
      background-color: color.adjust($bg-blue-green, $lightness: -10%);
    }
  }

  @include mobile {
    &.mobile-full {
      display: block;
      width: 100%;
    }
  }
}

.button-row {
  @extend %button-row;
}

.button-ghost {
  @extend %button-ghost;
}
