@use 'sass:math';
@use 'sass:meta';
@use 'variables' as var;
@use 'functions' as *;
@forward '../../icomoon/dki/variables';
@use '../../icomoon/dki/variables' as *;

$stack-icons: (
  empty: normal,
  cells: $i-cells,
  clipboard: $i-clipboard,
  collaboration: $i-collaboration,
  dna: $i-dna,
  generic: $i-double-chevron,
  examine-report: $i-examine-report,
  funding: $i-funding,
  grant-apply: $i-grant-apply,
  grant-award: $i-grant-award,
  grant-examine: $i-grant-examine,
  grant-fund: $i-grant-fund,
  grant-funding: $i-grant-funding,
  grant-manage: $i-grant-manage,
  grant-review: $i-grant-review,
  handshake: $i-handshake,
  id-badge: $i-id-badge,
  lightbulb: $i-lightbulb,
  medical-microscope: $i-medical-microscope,
  molecule: $i-molecule,
  mortar-pestle: $i-mortar-pestle,
  mortarboard: $i-mortarboard,
  mouse: $i-mouse,
  new-investigator: $i-new-investigator,
  newspaper: $i-newspaper,
  node-cluster: $i-node-cluster,
  people-discussing: $i-people-discussing,
  people-funding: $i-people-discussing, // legacy
  person-presenting: $i-person-presenting,
  robotic-arm: $i-robotic-arm,
  small-business: $i-small-business,
  test-tubes: $i-test-tubes,
  tools: $i-tools,
  additional-reading: $i-additional-reading,
  toolbox: $i-toolbox,
  weight-management: $i-weight-management,
  school-responsibilities: $i-school-responsibilities,
  risk-factors: $i-risk-factors,
  checklists: $i-checklists,
  blood-screening: $i-blood-screening,
  diabetes: $i-diabetes,
  download-file: $i-download-file,
  kidney-disease: $i-kidney-disease,
  clinical-trials: $i-clinical-trials,
  research-materials: $i-research-materials,
  publications: $i-publications,
  repository: $i-repository,
  lab-software: $i-lab-software,
  lab-servers: $i-lab-servers,
  pulse-programs: $i-pulse-programs,
  size-fill: $i-size-fill,
  standards: $i-standards,
  webinar: $i-webinar-s
);

$stack-shapes: (
  blank: normal,
  square: $i-shape-square,
  circle: $i-shape-circle,
  pentagon-arrow: $i-shape-pentagon-arrow,
  hexagon: $i-shape-hexagon
);

// Base style for DK Font icons
@mixin icon-base {
  display: inline-block;
  font: {
    style: normal;
    weight: normal;
    variant: normal;
    size: inherit;
    family: var.$dk-icons;
  }
  line-height: 1;
  text: {
    rendering: auto;
    transform: none;
    decoration: none;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Fix for icon hover state in IE
@mixin icon-hover-fix {
  & {
    text-decoration: underline;
  }
  @at-root {
    body & {
      text-decoration: none;
    }
  }
}

@mixin enable-icons {
  @include meta.load-css('../icomoon/dki/style.scss');

  :is([class^="i-"], [class*=" i-"], [data-icon])::before {
    @extend %icon-base;
  }

  [data-icon]::before {
    content: attr(data-icon);
  }

  // Inspired by FontAwesome https://github.com/FortAwesome/Font-Awesome/blob/v4.6.2/scss/_fixed-width.scss
  .i-fw {
    width: math.div(18em, 14);
    text-align: center;
  }
  .i-lg {
    font-size: math.div(4em, 3);
    line-height: math.div(3em, 4);
    vertical-align: -15%;
  }
  .i-2x { font-size: 2em; }
  .i-3x { font-size: 3em; }
  .i-4x { font-size: 4em; }
  .i-5x { font-size: 5em; }

  .i-150percent {
    font-size: 1.5em;
    margin-top: 0.125em;
  }

  svg.icon {
    // Flow with text content
    display: inline-block;
    // Inherit the parent text color
    fill: currentColor;
    // Use the parent font-size for width and height
    height: 1.5em;
    width: 1.5em;
    // Vertically align icon with adjacent text
    vertical-align: middle;
    // Align more nicely with capital letters
    position: relative;
  }

  .angle-right::after {
    @extend %angle-right-after;
  }

  // Aliases
  .i-people-funding::before { content: $i-people-discussing; } // Legacy class, replaced with .i-people-discussing
  .i-blog-icon::before { content: $i-blog; } // Legacy class, replaced with .i-blog

  // Options
  .i-video.red {
    color: var.$youtube-red;
  }
}

%icon-base {
  @include icon-base;
  position: relative;
  top: rem(1);
}

%chevron-circle-right {
  @include icon-base;
  content: $i-arrow-right;
  position: relative;
  margin-left: rem(3);
}

%double-chevron-right {
  @include icon-base;
  content: $i-angle-double-right;
  padding-left: 0.5em;
  font-size: 1.2em;
}

%angle-right-after {
  @include icon-base;
  content: $i-angle-right;
  padding-left: 0.5em;
  font-size: 1.2em;
  vertical-align: middle;
}
