@use '../../tools' as *;

// File icon sprite SVG
$file-icon-url: '../images/sprite/file-icons.svg';
// Default glyph when suffix isn't found
$file-icon-url-generic: $file-icon-url + '#file';
// Map of icon glyphs (key) to a list of `.file-{type}` class suffixes
$file-icon-map: (
  audio:      'audio' 'mp3' 'm4a',
  doc:        'doc' 'docx',
  excel:      'excel' 'xls' 'xlsx',
  image:      'image' 'gif' 'jpg' 'jpeg' 'png' 'svg' 'tif' 'tiff',
  pdf:        'pdf',
  powerpoint: 'powerpoint' 'ppt' 'pptx',
  video:      'video' 'mov' 'mp4' 'wmv',
  zip:        'zip',
  files:      'files' 'set' 'multiple'
);

%file-icon {
  $size: 1.25em;

  display: inline-block;
  width: $size;
  height: $size;
  margin-top: -0.5 * ($size - 1);
  vertical-align: middle;
}

:is(.file, [class^="file-"], [class*=" file-"]):where(:not(.file-size))::before {
  @extend %file-icon;
  content: url($file-icon-url-generic);
  font-size: 1.01em;
}

@each $id, $suffixes in $file-icon-map {
  $sel: join-string($suffixes, $prefix: '.file-');

  :is(#{$sel})::before {
    $url: $file-icon-url + '#' + $id;
    content: url($url);
  }
}
