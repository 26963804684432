@use 'sass:map';
@use '../variables' as var;

// Minimum pixel width of each responsive breakpoint
$_xl-min: if(var.$xl-min, var.$xl-min, 1440px) !default;
$_desktop-min: if(var.$desktop-min, var.$desktop-min, 1025px) !default;
$_tablet-min: if(var.$tablet-min, var.$tablet-min, 650px) !default;
$_fixed-layout-min: if(var.$fixed-layout-min, var.$fixed-layout-min, 872px) !default;

// Calculated maximum widths
$_desktop-max: if(var.$desktop-max, var.$desktop-max, $_xl-min - 1px) !default;
$_tablet-max: if(var.$tablet-max, var.$tablet-max, $_desktop-min - 1px) !default;
$_mobile-max: if(var.$mobile-max, var.$mobile-max, $_tablet-min - 1px) !default;
$_variable-layout-max: if(var.$variable-layout-max, var.$variable-layout-max, $_fixed-layout-min - 1px) !default;

$_breakpoints: (
  desktop:        (min: $_tablet-min),
  mobile:         (max: $_mobile-max),
  desktop-only:   (min: $_desktop-min),
  mobile-tablet:  (max: $_tablet-max),
  tablet:         (min: $_tablet-min, max: $_tablet-max),
  fixed-layout:   (min: $_fixed-layout-min),
  variable-layout:   (max: $_variable-layout-max)
) !default;

@function _determine_media($addPrint: false) {
  @return if($addPrint, "print, screen", "screen");
}

@function _get_media_query($min: null, $max: null, $addPrint: false) {
  $addl: '';
  @if $min and $max {
    $addl: ' and (min-width: ' + $min + ') and (max-width: ' + $max + ')';
  } @else if $min {
    $addl: ' and (min-width: ' + $min + ')';
  } @else if $max {
    $addl: ' and (max-width: ' + $max + ')';
  }
  @return _determine_media($addPrint) + $addl;
}

@mixin breakpoint($name: null, $min: null, $max: null, $addPrint: false) {
  $vals: map.get($_breakpoints, $name);
  @if $vals {
    $min: map.get($vals, 'min');
    $max: map.get($vals, 'max');
  }
  $mq: _get_media_query($min, $max, $addPrint);
  @media #{$mq} {
    @content;
  }
}

@mixin desktop($addPrint: false) {
  @include breakpoint($name: 'desktop', $addPrint: $addPrint) {
    @content;
  }
}

@mixin mobile($addPrint: false) {
  @include breakpoint($name: 'mobile', $addPrint: $addPrint) {
    @content;
  }
}

@mixin desktop-only($addPrint: false) {
  @include breakpoint($name: 'desktop-only', $addPrint: $addPrint) {
    @content;
  }
}

@mixin mobile-tablet($addPrint: false) {
  @include breakpoint($name: 'mobile-tablet', $addPrint: $addPrint) {
    @content;
  }
}

@mixin tablet($addPrint: false) {
  @include breakpoint($name: 'tablet', $addPrint: $addPrint) {
    @content;
  }
}

@mixin fixed-layout($addPrint: false) {
  @include breakpoint($name: 'fixed-layout', $addPrint: $addPrint) {
    @content;
  }
}

@mixin variable-layout($addPrint: false) {
  @include breakpoint($name: 'variable-layout', $addPrint: $addPrint) {
    @content;
  }
}

// Print media only
@mixin print {
  @media print {
    @content;
  }
}
