@use 'sass:list';
@use 'sass:map';
@use 'sass:math';
@use '../../tools' as *;

.ri-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .ri-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: rem(3);
    padding: rem(10);
    box-shadow: $tile-shadow;
    text-align: center;

    &:hover {
      cursor: pointer;
      box-shadow: 0 rem(1) rem(12) $button-outline;
    }

    & > .ri-image {
      margin-bottom: 0.75rem;
      position: relative;
      @extend %constrained-box;
      @extend %constrained-box-centered;

      @include ratio(4, 3);

      .corner {
        transform: none;

        @extend %video-corner;
      }
    }

    .ri-title {
      font-family: $open-sans;
      font-weight: $semibold;
      font-size: 0.85rem;

      &.line-clamp {
        @include line-clamp-grow(5, 11.9);
      }
    }

    .ri-description {
      display: none;
    }

    .ri-video {
      display: none;
    }
  }

  $ri-grid-sizes: (
    two:   (tablet: 2 2.5%),
    three: (tablet: 3 2%),
    four:  (tablet: 3 2%,     desktop: 4 1.5%),
    five:  (tablet: 4 1.5%, desktop: 5 1%),
    lib:   (tablet: 2 2.5%,  desktop: 3 2%)
  );

  @mixin ri-card-size($sizes) {
    $cols: list.nth($sizes, 1);
    $margin: list.nth($sizes, 2);
    $width: math.div(100%, $cols) - math.div(($cols - 1) * $margin, $cols);

    width: $width;
    margin-bottom: $margin;

    &:not(:nth-child(#{$cols}n)) {
      margin-right: $margin;
    }

    &:nth-child(#{$cols}n) {
      margin-right: 0;
    }
  }

  @include desktop {
    &:not([class*="ri-cols-"]) .ri-card {
      // Use three-column layout by default
      @include ri-card-size(map.get(map.get($ri-grid-sizes, three), tablet));

      // If there are only one or two cards, use two-column layout
      &:first-child:last-child,
      &:first-child:nth-last-child(2) {
        &, & ~ * {
          @include ri-card-size(map.get(map.get($ri-grid-sizes, two), tablet));
        }
      }
    }

    // Explicit grid sizing (tablet+)
    @each $gsize, $grid-size in $ri-grid-sizes {
      &.ri-cols-#{$gsize} .ri-card {
        @include ri-card-size(map.get($grid-size, tablet));
      }
    }
  }

  // The four- and five-column layouts have different sizes on desktop
  @include desktop-only {
    @each $gsize, $grid-size in $ri-grid-sizes {
      @if (list.length($grid-size) > 1) {
        &.ri-cols-#{$gsize} .ri-card {
          @include ri-card-size(map.get($grid-size, desktop));
        }
      }
    }
  }

  @include mobile {
    .ri-card {
      margin-bottom: 1rem;

      p {
        display: none;
      }
    }
  }
}

.mfp-content.mfp-content.ri-popup-container {
  width: auto;
  max-width: rem(860);
  border-radius: rem(5);
  padding: 0;
  box-shadow: 0 rem(1) rem(6) $black;
  font-family: $open-sans;

  .ri-popup {
    display: flex;
    padding: rem(20);

    &::before {
      content: normal;
    }

    button.mfp-close {
      width: 1.75rem;
      height: 1.75rem;
      border-radius: rem(5);
      color: $text-dark;

      &:focus {
        box-shadow: 0 0 2px 2px #5B9DD9;
      }
    }

    .ri-popup-image {
      align-self: flex-start;
      min-width: rem(215);
      max-width: rem(430);
      margin-right: 2rem;

      img {
        display: block;
        max-width: 100%;
        max-height: rem(430);
        margin: 0 auto;
        object-fit: contain;
      }

      iframe {
        display: block;
        max-width: 100%;
        margin: 0 auto;
      }

      a.ri-expand {
        display: block;
        text-align: right;

        &::before {
          @extend %icon-base;
          @extend %icon-hover-fix;
          content: $i-search-plus;
          color: $text-general;
          margin-right: 0.25rem;
          font-size: 1rem;
        }
      }
    }

    .ri-popup-information {
      min-width: 50%;
      max-width: rem(430);
      max-height: rem(645);
      overflow: auto;

      .ri-popup-title {
        margin: 0 0 1rem;
        border-bottom: 1px solid $divider-stroke;
        padding-bottom: 1rem;
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  }

  @include mobile {
      max-height: 90vh;
      width: 90vw;
      overflow: auto;

      .ri-popup {
        flex-direction: column;

        .ri-popup-image, .ri-popup-information {
          width: 100%;
          margin-right: 0;
        }

        .ri-popup-image {
          margin-bottom: 1rem;

          img {
            max-height: 45vh;
          }

          iframe {
            width: auto;
            height: auto;
          }
        }
      }
    }
}
