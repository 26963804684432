@use 'sass:color';
@use 'sass:math';
@use '../../../tools' as *;
@use '../../../common/structure/site-search';

.dk-site-search {
  @extend %dk-site-search;
}

.stripe-brand .dk-site-search {
  @extend %dk-header-site-search;
}

%dk-header-site-search {
  input[type=text] {
    width: rem(250);
    max-width: 28vw;
  }

  .site-search-info, .close-mobile-site-search {
    display: none;
  }

  @include mobile {
    display: none;
    position: fixed;
    top: 8rem;
    right: 0;
    left: 0;
    z-index: 90;
    width: 90vw;
    margin: 0 5vw;
    border-radius: rem(3);

    @at-root .search-overlay & {
      display: block;
    }

    .site-search-info {
      display: block;
      position: absolute;
      top: -3rem;
      left: 0;
      z-index: 80;
      opacity: 1;
      color: $white;
      font-size: 1.1rem;
      font-weight: 300;
      font-family: $open-sans;
    }

    .close-mobile-site-search {
      display: block;
      position: absolute;
      top: -7rem;
      right: -0.5rem;
      height: auto;
      border: 0;
      padding: 0.25rem 0.5rem;
      background: transparent;
      text-align: center;
      font-size: 1.7rem;
      color: $white;
      cursor: pointer;
    }

    input[type=text] {
      width: calc(100% - #{rem(105)});
      max-width: none;

      @include breakpoint($max: 374px) {
        padding: 0 0.5em;
      }
    }
  }
}

// Search results page
.sr-scopes {
  display: block;
  font-family: $open-sans;
  font-size: 1rem;
  cursor: default;
  @extend %unselectable;

  li button {
    font: inherit;
    display: inline;
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    line-height: 1;
    color: $text-general;
  }

  @include desktop {
    & > button {
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      border: solid 0 $light-gray;
      border-width: 0 0 1px;

      li {
        display: inline-block;
        font-weight: $semibold;
        margin: 0;
        position: relative;
        border: dashed 0 $white;
        border-width: 1px 1px 0;
        border-radius: rem(5) rem(5) 0 0;

        & > span,
        button > span {
          padding: rem(10) 1.2rem 0;
          line-height: math.div(rem(45), 1rem);
        }

        button {
          line-height: 1.5;
          color: $blue;
          cursor: pointer;
          border: dashed 0 $white;
          border-width: 1px 1px 0;
          border-radius: rem(5) rem(5) 0 0;

          &:focus {
            outline: none;
            border-color: $light-gray;
          }

          &:hover {
            background-color: $bg-ash;
          }
        }

        &.current {
          background-color: $ln-current;
          font-weight: $semibold;
          color: $text-dark;
          border: 0;

          &::after {
            $size: rem(6);

            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: calc(50% - #{$size});
            width: 0;
            height: 0;
            // cssnano seems to be messing this up if consolidated, so leave this border specification broken apart
            border-style: solid;
            border-width: 0 $size $size;
            border-color: transparent transparent $text-general;
          }
        }
      }
    }
  }

  @include mobile {
    position: relative;
    display: block;
    width: rem(200);

    @include dropdown-generic;

    & > button {
      display: block;
      width: 100%;
      height: 2.5rem;
      border: 1px solid $button-outline;
      border-radius: rem(3);
      font-weight: $semibold;
      font-size: rem(14);
      background-color: $white;
      margin-bottom: rem(17);
      cursor: pointer;

      span {
        display: block;
        position: relative;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        padding-left: rem(22);

        &::before {
          @include icon-base;
          content: $i-angle-down;
          display: block;
          position: absolute;
          left: rem(2);
          top: calc(50% - #{rem(12.5)});
          padding: rem(5) 0;
          color: $link-standard;
        }
      }
    }

    ul {
      top: calc(100% + #{rem(5)});
      left: rem(-5);

      li {
        margin: 0;
        padding: 0;

        &.current {
          line-height: 1;
        }

        &:first-child {
          border-radius: rem(3) rem(3) 0 0;
        }

        &:last-child {
          border-radius: 0 0 rem(3) rem(3);
        }

        & > * {
          display: block;
          text-align: left;
          padding: 0.6rem {
            right: 3rem;
          }
        }
      }

      &::before {
        left: rem(5);
      }
    }
  }
}

.sr-status {
  margin: 2rem 0;

  .sr-scope {
    &::before {
      content: '‘';
    }
    &::after {
      content: '’';
    }
  }

  ol {
    display: inline;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: inherit;

    li {
      display: inline;
      margin: 0;
      padding: 0;
      font: {
        weight: bold;
        size: 1rem;
      }

      &:not(:last-of-type)::after {
        content: ':';
      }

      &:last-of-type::after {
        content: '.';
        font-weight: normal;
      }
    }
  }
}

.sr-suggestion {
  margin: 1rem 0;
  font-weight: bold;

  button {
    font: inherit {
      style: italic;
    }
    display: inline;
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0 rem(2);
    line-height: 1;
    color: $link-standard;
    cursor: pointer;

    &:hover, &:active {
      color: $link-hover;
      text-decoration: underline;
    }
  }
}

.sr-results {
  margin: 2rem 0;
  list-style: none;

  li {
    margin: 0;
  }

  .sr-url {
    display: block;
    margin: 0.2rem 0;
    color: $green;
    font-family: $open-sans;

    @include mobile {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  p {
    margin-top: 0;
    line-height: 1.7;
  }
}

main .sr-results h2 {
  @extend %size-h3;
  line-height: 1.2;
  margin-bottom: 0;

  .file-size {
    font-size: 1rem;
    font-weight: normal;
  }

  i[class*="file-"] {
    font-size: 1rem;
  }
}

.sr-refine {
  background: $bg-ash; //#f8f8f8;
  border: 1px solid $button-outline;
  margin: 2rem 0;
  padding: 1.5rem;

  @include desktop {
    max-width: rem(432);
  }

  label {
    display: inline-block;
    margin: 0 1rem 1rem 0;

    &.disabled {
      color: color.mix(#fff, $text-general, 20%);
      cursor: not-allowed;

      input {
        cursor: not-allowed;
      }
    }
  }

  .sr-search-field {
    @include row;

    button, input {
      border: 1px solid $button-outline;
      color: $text-general;
      font: {
        family: $open-sans;
        size: rem(12.5);
      }
    }

    input {
      padding: 0.75rem 1rem;
      border-radius: rem(3) 0 0 rem(3);
      appearance: none;

      @include span-columns(3 of 4);
      @include omega;

      &::placeholder {
        color: color.mix(#fff, $button-text, 25%);
      }
    }

    button {
      max-width: rem(80);
      min-width: rem(49);
      padding: 0.75rem 0;
      border: {
        left: 0;
        radius: 0 rem(3) rem(3) 0;
      }
      background: $button-gray;

      @include span-columns(1 of 4);

      &:hover, &:active {
        background: color.mix(#000, $button-gray, 10%);
        cursor: pointer;
      }
    }
  }
}
