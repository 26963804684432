@use 'sass:color';
@use 'sass:list';
@use 'sass:math';
@use '../../../tools' as *;
@use 'live-chat-prechat';

$_size: #{rem(50)};

html {
  --chat-badge-size: #{$_size};
}

niddk-chatcontrol {
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  z-index: -3000;
}

niddk-chatbadge {
  display: block;
  width: var(--chat-badge-size);
  height: var(--chat-badge-size);
}

.chat-floating {
  display: none;
  position: sticky;
  bottom: 0;
  width: 100%;

  @at-root body.livechat-showbadge & {
    display: block;
  }

  niddk-chatbadge {
    position: absolute;
    right: 1.5rem;
    bottom: 2.5rem;

    @include desktop {
      $_ctr-pad: rem(list.nth($container-pad, 1));
      $_ctr-half: rem(math.div($container-width, 2));

      right: auto;
      left: min(50% + #{$_ctr-half}, 100% - var(--chat-badge-size) - #{$_ctr-pad - 0.5rem});
    }
  }
}

.chat-prechat-modal-wrap {
  margin: 2rem auto;
  border: 1px solid $green;
  border-radius: 10px;
  padding: 1rem;
  max-width: #{rem(500) + 5rem};
}

// Suppress default ChatBeacon button
#chatBeaconBtn {
  display: none;
}

// Hide ChatBeacon window when printing
@include print {
  #chatBeaconCtrl {
    display: none !important;
  }
}

// Make sure live chat window can be minimized at all sizes
#chatBeaconCtrl {
  display: none;

  &.open {
    display: block;
  }
}
