// General structure and layout styles

@use '../../tools' as *;

html:not(.editmode) :is(#{$hispec-id}, .editor-only, .meta-edit) {
  display: none;
}

main > section {
  padding: rem(10) 0;
}

section.centered-white {
  color: $white;
  font-family: $open-sans;
  text-align: center;
  padding: 2rem 0 3rem;

  h2 {
    font-weight: normal;
    font-family: $merriweather;
    font-size: 1.5rem;
    border-bottom: 0.1rem solid $bg-gray;
    display: inline-block;
    padding-bottom: 1.2rem;
    margin: 0 rem(10);
  }

  p {
    font-size: rem(17.43);
    margin: 1.2rem;
    color: $white;
  }

  @include print {
    color: $dark-gray;

    p {
      color: $dark-gray;
    }
  }
}

// Grid sizing
main.left-nav {
  nav {
    @include span-columns(3);
  }

  .main-content {
    @include span-columns(9);
  }
}

.thirds {
  @include span-columns(4);
}

.half {
  @include span-columns(6);
}

.two-thirds {
  @include span-columns(8);
}

.dk-breadcrumb-row { // will also include language dropdown
  display: block;
  margin: rem(14) 0;
  position: relative;
  z-index: 27; // Above subscribe/follow (25) and below main nav (30)

  @include clearfix;

  @include desktop(true) {
    display: flex;
  }

  .dk-breadcrumbs {
    display: none;
    flex: 6;
    margin-top: 0.6rem;

    @include desktop(true) {
      display: block;
    }

    ol {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: inline-block;
        font-family: $open-sans;
        font-size: 1rem;
        margin: 0;
        color: #343638;

        a {
          text-decoration: none;
          font-weight: bold;

          &, &:visited {
            color: $link-hover;
          }

          &:is(:hover, :active) {
            text-decoration: underline;
          }
        }
        &:not(:last-child)::after {
          content: var(--page-breadcrumb-separator, ' \\ ');
        }
      }
    }
  }
}

.dk-main-container {
  position: relative;
  background: $white;
  box-shadow: 0 rem(1.5) rem(16) rgba($black, 0.1);
  margin: 1rem 0;
  font-family: $merriweather;
  padding: 0 rem(20);

  @include row;

  @include mobile {
    padding: 0 rem(10) rem(35);
  }

  @include print {
    box-shadow: none;
    padding: 0;
  }

  .dk-leftnav {
    margin: {
      top: 1.5rem;
      bottom: 1.5rem;
    }

    @include span-columns(3);
  }

  .landing-hero {
    @include row;

    @include desktop {
      & > :first-child {
        @include span-columns(8);

        & > :first-child {
          margin-top: 0;
        }
      }

      & > :last-child {
        @include span-columns(4);

        &.right {
          margin: {
            top: 0;
            left: 0;
          }
        }
      }

      & > figure:last-child {
        display: table;
      }

      // Full width when there is only one child
      & > :first-child:last-child {
        @include span-columns(12);
      }
    }
  }

  // Content area takes up 9 columns instead of 12 when left nav is present
  .dk-leftnav + .dk-content {
    @include desktop {
      max-width: rem(688);

      @include span-columns(9);
    }

    @include mobile {
      max-width: rem(628);
    }
  }

  .dk-content {
    padding: rem(14);
    min-height: rem(400);

    @include span-columns(12);

    p.dk-intro {
      font-weight: bold;
    }

    // reviewed date
    .dk-review-date {
      @extend %block-vmargin;
      @extend %dk-paragraph-text;
      font-weight: bold;
    }

    & > article {
      border-bottom: solid 1px $divider-stroke;
    }
  }
}

// Overlays
.site-wrap {
  position: relative;

  .page-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -100;
    height: 100%;
    width: 100%;
    transition: background-color 0.25s, z-index 0s 0.25s;
  }
}

@include mobile {
  .site-wrap.overlay .page-mask {
    @include page-mask;
  }

  .site-wrap.search-overlay .page-mask {
    @include page-mask(60, $black, 0.9);
  }

  .site-wrap.popup-overlay .page-mask {
    @include page-mask(50, $black, 0.5);
  }

  // Used to stop scrolling on body (deprecated)
  body.modal-open {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.site-wrap.subscribe-overlay .page-mask {
  @include page-mask(40, $white, 0.5);
}

// hero unit/top banner
.hero-unit {
  width: 100%;
  padding: 0;
  position: relative;
  background-size: 100% 23rem;
  background-repeat: no-repeat;
  background-position: center top;

  @include mobile {
    background-size: cover;
  }
}

// "Return to" faceted search link
.fs-return-link {
  &.hide-no-ref {
    display: none;
  }

  a {
    font-family: $open-sans;

    &:is(:link, :visited, :hover, :active) {
      color: $text-general;
    }

    &::before {
      @extend %icon-base;
      @extend %icon-hover-fix;
      content: $i-arrow-left;
      position: relative;
      top: 2px;
      margin-right: rem(5);
    }
  }
}
