@use 'sass:color';
@use 'sass:math';
@use '../../tools' as *;

$box-spacing: 10;
$box-margin: 1.5rem;

%box-thin {
  border-style: solid;
  border-width: 1px;
  @extend %accent-border;

  &.accented {
    border-top-width: rem(8);
  }

  :is(h1, h2, h3) {
    @extend %size-h4;
    font-weight: bold;
  }
}

%box-thick {
  $thickness: rem(10);
  $border-color: $button-gray;

  position: relative;
  border: $thickness solid $border-color;

  & > * {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 rem(5) rem(2) color.mix(#000, $border-color, 5.5%);
  }

  :is(h1, h2, h3) {
    @extend %size-h3;
    font-family: $merriweather;
    font-weight: normal;

    &:not(:last-child) {
      padding-bottom: rem($box-spacing);
      border-bottom: solid 1px $divider-stroke;
    }
  }

  %box-thick-colored-headings {
    &, a {
      border: 0;
      padding: rem($box-spacing);
      margin: rem(-1 * $box-spacing);
      color: $white;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    a {
      display: block;
      text-decoration: underline;

      &:hover {
        background-color: rgba($black, 0.1);
      }
    }
  }

  @include with-accent-class {
    :is(h1, h2, h3) {
      @extend %box-thick-colored-headings;
      @extend %accent-background;
    }
  }
}

%box-filled {
  border: 0;

  &:not([class*="bg-"]) {
    @include without-accent-class {
      background-color: $bg-gray;
    }
  }

  @include with-accent-class {
    @extend %accent-background;

    * {
      color: $white;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

%box-all {
  display: block;
  margin: 0 0 $box-margin 0;
  padding: rem($box-spacing);

  @extend %clearfix;

  &:not(:nth-child(1)) {
    margin-top: 0;
  }

  &.thin {
    @extend %box-thin;
  }

  &.thick {
    @extend %box-thick;
  }

  &.filled {
    @extend %box-filled;
  }

  :is(h1, h2, h3) {
    margin: rem($box-spacing) 0;
  }

  &.bg-gray {
    :is(h1, h2, h3) {
      @extend %size-h4;
      font-weight: bold;
    }
  }

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  figure, .figure {
    img {
      width: 100%;
    }
  }

  p {
    margin-top: rem($box-spacing);
    margin-bottom: rem($box-spacing);
  }

  &, & > .body {
    & > * {
      margin-top: rem($box-spacing);
      margin-bottom: rem($box-spacing);

      &:empty {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    @extend %remove-child-bookend-margins;
  }
}

%box-adjacent-top-margin-adjust {
  @include desktop {
    & + .box, & + .boxes {
      margin-top: rem($box-spacing) - $box-margin;
    }
  }
}

.box {
  @extend %box-all;
  @extend %box-adjacent-top-margin-adjust;
  @extend %accent-border;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  @extend %accent-border;

  & > * {
    @extend %box-all;
    display: block;
    width: 100%;
    margin: 0;
  }

  section {
    margin-bottom: rem($box-spacing);

    & > :first-child {
      margin: 0;
    }
  }
}

@include desktop {
  .boxes {
    margin: rem($box-spacing) 0;
    margin-bottom: $box-margin;

    &.boxes > * {
      flex: 1;
      margin: 0;
      margin-right: rem($box-spacing);

      &:last-child {
        margin-right: 0;
      }

      img.right, img.left {
        width: 40%;
        margin-top: 0;
        margin-bottom: 0;
      }

      img.bottom-margin {
        margin-bottom: rem($box-spacing);
      }
    }

    &.boxes + &.boxes {
      margin-top: 0 - rem($box-spacing);
    }

    & > :first-child:nth-last-child(2) {
      &.two-thirds, & ~ .two-thirds {
        flex: none;
        width: calc(#{math.percentage(math.div(2, 3))} - #{rem(math.div($box-spacing, 3))});
        float: none;
      }

      &.three-quarters, & ~ .three-quarters {
        flex: none;
        width: calc(#{math.percentage(math.div(3, 4))} - #{rem(math.div($box-spacing, 4))});
        float: none;
      }
    }

    & > :first-child:nth-last-child(3) {
      &.half, & ~ .half {
        flex: none;
        width: calc(#{math.percentage(math.div(1, 2))} - #{rem(math.div($box-spacing, 2))});
        float: none;
      }
    }
  }
}

// Specialized "thin box"
.thin-box {
  @extend %thin-box;

  &.accented {
    border-top-width: rem(8);
  }
}

// Specialized "filled box"
// TODO: eval removal - not used except for legacy NDEP, NKDEP (no longer on Prod)
.box-filled {
  margin: 1rem 0;
  padding: rem(10);
  color: $white;
  text-align: center;
  text-shadow: 1px 1px rgba($black, 0.5);

  @extend %accent-background;

  :is(h1, h2, h3) {
    @extend %size-h2;
    font-family: $open-sans;
  }

  * {
    color: $white;
  }

  .button-pair {
    display: flex;
    margin: 2rem;

    & > * {
      display: flex;
      align-self: stretch;
      align-items: center;
      flex: 1;
      width: 100%;
      padding: 0.5rem 0.5rem;
      margin: 0 0.5rem;
      text-align: center;
      line-height: 1.3;

      & > span {
        width: 100%;
      }

      &.button-ghost::after {
        content: normal;
      }
    }

    @include mobile {
      display: block;

      & > * {
        max-width: rem(300);
        margin: 0 auto;
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
