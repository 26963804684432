@use 'sass:color';
@use 'sass:math';
@use '../../../tools' as *;

.chat-langtoggle {
  --opt-width: #{rem(70)};

  position: relative;
  display: grid;
  grid-template: 1.75rem / 1fr 1fr;
  align-items: center;
  margin: rem(6);
  font-family: $open-sans;
  font-weight: $semibold;
  font-size: 1rem;
  line-height: 1.75;
  text-align: center;

  @extend %unselectable;

  @include desktop {
    --opt-width: #{rem(84)};
  }

  &::before {
    content: '';
    position: absolute;
    inset: rem(-6);
    border-radius: 2rem;
    background-color: $link-standard;
    box-shadow: inset 0 rem(1) rem(10) rgba($black, 0.25);
  }

  input {
    position: absolute;
    left: -5000px;
    z-index: -500;

    &:checked + label {
      color: $link-standard;
      cursor: default;
    }
  }

  label {
    position: relative;
    display: block;
    width: var(--opt-width);
    color: $white;
    z-index: 2;
    cursor: pointer;
    transition: color 150ms ease-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset-block: 0;
    z-index: 1;
    border-radius: 2rem;
    box-shadow: 0 rem(1.5) rem(5) rgba($black, 0.125);
    background-color: $white;
    color: $link-standard;
  }

  @supports selector(:focus-within:has(:focus-visible)) {
    &:focus-within:has(:focus-visible)::before {
      outline: 2px solid $black;
      outline-offset: 2px;
    }
  }

  @supports selector(:focus-visible) and (not selector(:focus-within:has(:focus-visible))) {
    input:focus-visible + label {
      outline: 2px solid $text-red;
    }
  }

  @supports not selector(input:focus-visible) {
    input:focus-visible + label {
      outline: 2px solid $text-red;
    }
  }

  // Use data attribute to fix problems with :has() selector in Safari shadow DOM
  &[data-value=en] ~ .chat-toggle:not(.en),
  &[data-value=es] ~ .chat-toggle:not(.es) {
    display: none;
  }

  @supports selector(:has(*)) {
    // Use :has() selector outside shadow DOM
    &:where(:not([data-value]):has(.en:checked)) ~ .chat-toggle:not(.en),
    &:where(:not([data-value]):has(.es:checked)) ~ .chat-toggle:not(.es) {
      display: none;
    }

    &:has(.en:checked)::after {
      left: 0;
      right: 50%;
      transition: left 150ms ease-in, right 150ms ease-out 100ms;
    }

    &:has(.es:checked)::after {
      left: 50%;
      right: 0;
      transition: left 150ms ease-out 100ms, right 150ms ease-in;
    }
  }

  @supports not selector(:has(*)) {
    &:not([data-value]) {
      display: none;
    }
  }
}

// Pre-chat disclaimers/language pop-up
.chat-prechat-modal {
  display: grid;
  justify-items: center;
  gap: 1.5rem;
  max-width: #{rem(500) + 2rem};
  margin-inline: auto;
  padding: 1rem;
  font-family: $open-sans;
  color: $text-general;
  text-align: left;

  h2 {
    margin: 0;
    font-size: 1rem;
    font-weight: $semibold;

    &:focus {
      outline: none;
    }
  }

  .strong {
    font-weight: $semibold;
  }

  .chat-toggle {
    display: contents;
  }

  .chat-disclaimer {
    overflow-y: auto;
    border: 1px solid $button-outline;
    border-radius: rem(5);
    padding: 0 0.5rem;
    line-height: 1.5;
    height: 8em;
    height: 5.1lh;
  }

  .chat-omb {
    text-align: center;
  }

  niddk-chatlaunch {
    color: transparent;

    &, &::part(launch) {
      display: block;
      position: relative;
      width: 100%;
      max-width: rem(300);
      height: rem(51);
    }

    &::part(launch) {
      overflow: hidden;
      padding: 1rem;
      border: 0;
      border-radius: rem(5);
      font-size: 1.4rem;
      font-weight: 600;
      background: #48602d;
      color: $white;
      appearance: none;
      cursor: pointer;

      @extend %unselectable;

      &:focus-visible {
        outline: 2px solid $black;
        outline-offset: rem(3);
      }

      @supports not selector(:focus-visible) {
        &:focus {
          outline: 2px solid $black;
          outline-offset: rem(3);
        }
      }

      &:hover::before {
        transform: translateX(60%);
      }

      &::before {
        content: '';
        position: absolute;
        inset-block: 0;
        width: 250%;
        left: -150%;
        z-index: 0;
        transition: transform ease-in-out 300ms;
        background: linear-gradient(125deg, #607f3c 50%, #48602d 50%);
      }

      &::after {
        display: block;
        position: absolute;
        inset-inline: 30%;
        bottom: #{math.div(1rem - rem(4), 2)};
        height: rem(4);
        --c: no-repeat linear-gradient(#fffa 0 0);
        background: var(--c),var(--c);
        background-size: 60% 100%;
        animation: chatBusy 3s infinite linear;
      }
    }

    &::part(busy)::after {
      content: '';
    }

    &::part(text) {
      position: relative;
      z-index: 0;

      &::before {
        @extend %icon-base;

        content: $i-live-chat;
        margin-right: 1rem;
      }
    }
  }
}

// These keyframes also need to be added to the shadow DOM for niddk-chatlaunch
@keyframes chatBusy {
  0%   {background-position:-150% 0,-150% 0}
  50%  {background-position: 252% 0,-150% 0}
  100% {background-position: 252% 0, 252% 0}
}
