@use 'sass:color';
@use '../../tools' as *;

.dk-main-container {
  position: relative;

  .dk-subscribe-panel {
    position: absolute;
    top: rem(15);
    right: 0;
    z-index: 25;
    height: rem(98);
    width: rem(20);
    transition: z-index 0s 0.25s;

    @include desktop {
      right: rem(-9);
    }

    & > :first-child {
      display: block;
      position: absolute;
      top: rem(98);
      right: 0;
      z-index: 2;
      width: rem(90);
      height: rem(20);
      border: 0;
      background-color: $orange;
      color: $white;
      font: {
        weight: $semibold;
        size: rem(12);
        family: $open-sans;
      }
      line-height: rem(20);
      letter-spacing: 1px;
      box-shadow: 1px -1px rem(5) $medium-gray;
      transform: rotate(90deg) {
        origin: 100% 0;
      }
      text: {
        transform: uppercase;
        align: center;
      }
      cursor: pointer;
      @extend %unselectable;

      @include desktop {
        border-radius: 0 rem(5) 0 0;

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: rem(-4);
          top: 0;
          z-index: 5;
          width: rem(4);
          height: rem(8);
          background-color: $orange;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          left: rem(-8);
          top: 0;
          z-index: 5;
          width: rem(8);
          height: rem(8);
          background-color: color.adjust($orange, $lightness: -15%);
          border-radius: rem(5) rem(5) 0 0;
        }
      }
    }

    & > a:first-child {
      &:visited {
        color: $white;
      }
      &:hover, &:active {
        text-decoration: none;
      }
    }

    &.opened {
      z-index: 45;
      transition: z-index 0s;

      .tray {
        max-width: rem(192);
        transition: max-width ease-in-out 750ms;
      }
    }

    .tray {
      position: absolute;
      top: rem(8);
      right: rem(8);
      z-index: 1;
      height: rem(90);
      max-width: 0;
      overflow: hidden;
      background-color: $white;
      border: solid 0 $orange {
        radius: rem(5) 0 0 rem(5);
        width: 2px 0 2px 2px;
      }
      transition: max-width ease-in-out 500ms;
      box-shadow: -1px 1px rem(5) $light-gray;

      &:focus {
        outline: none;
      }

      .links {
        width: rem(180);
        height: rem(90);
        padding: rem(10);
        background-color: $white;
      }

      a {
        display: block;
        font-family: $open-sans;
        text-decoration: none;
        color: $link-standard;
        line-height: rem(35);

        i {
          margin-right: rem(5);
        }

        &:hover {
          text-decoration: underline;
          color: $link-hover;
        }
      }
    }
  }
}
