// Triple card component (home page, info de la salud)

@use '../../../tools' as *;

.dk-card-container {
  background: $bg-white;
  box-shadow: 0.1rem 0.1rem 0.3rem $light-gray;
  padding: 1rem 1.5rem;
  font-family: $open-sans;

  @include row;

  h2 {
    @extend %h2-standard;
    border-bottom: 0.1rem rgba($divider-stroke, 0.5) solid;
  }

  &.dk-box h2 {
    margin: 0;
    padding-top: 0;
  }
}

.dk-card-row {
  overflow: hidden;

  @include row;
}

.dk-box:not(.dk-card-container) > .dk-card-row {
  margin: 0 1rem 1rem;
}

.dk-box.dk-box .dk-box-content > .dk-card-row {
  &:first-child {
    margin-top: rem(-20);
  }

  &:last-child {
    margin-bottom: rem(-6);
  }
}

.dk-box.border-thick .dk-box-content > .dk-card-row {
  margin-left: rem(-6);
  margin-right: rem(-6);
}

.dk-card {
  background: $bg-gray;
  margin: {
    top: 1rem;
    bottom: -20rem;
  }
  padding-bottom: 20rem;
  font-family: $open-sans;

  @include span-columns(4);
  @include omega(4n);

  img {
    max-width: 100%;
    height: auto;
  }

  &.media-card {
    .dk-card-title {
      color: $text-general;
    }
  }

  .dk-card-title {
    display: block;
    text-decoration: none;
    color: $link-standard;
    font-weight: $semibold;
    font-size: rem(16); // This should be 18
    margin: 0.6rem 0;
    padding: 0 0.8rem 0;
  }

  p {
    margin: 0.6rem 0 1rem;
    padding: 0 0.8rem 0;
    line-height: 1.4;
    font-size: rem(14);
  }

  @include mobile {
    padding: rem(10) rem(5);
    margin-bottom: 0;

    &.dk-card img {
      float: left;
      width: 30%;
    }

    p, .dk-card-title {
      float: right;
      padding: 0;
      width: calc(70% - #{rem(7)});
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }

    @include span-columns(12);
  }
}

a.dk-card {
  outline: none;

  &:visited {
    .dk-card-title {
      color: $link-visited;
    }
  }

  &:hover, &:active, &:focus {
    text-decoration: none;

    .dk-card-title {
      color: $link-hover;
      text-decoration: underline;
    }
  }

  p {
    color: $text-general;
  }
}
