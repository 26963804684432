@use '../tools' as *;

// Base font size (1rem)
html, body {
  margin: 0;
  font-size: $em-base;

  @if $tablet-scale and $tablet-scale != 1 {
    @include tablet {
      font-size: $em-base * $tablet-scale;
      -webkit-text-size-adjust: 100%;
      text-size-adjust: 100%;
    }
  }
}

// Body color (page background)
body {
  background-color: $bg-outer-body; // #eeeded
  font-family: $font-sans;
  color: $text-dark;
}

// Set up anchor margins
[id],
a[name]:where(:not([href])) {
  @extend %anchor-margin;
}
