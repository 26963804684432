@use 'sass:color';
@use 'sass:list';
@use 'sass:math';
@use 'media-queries' as *;
@use '../variables' as *;
@use '../functions' as *;

@mixin arrow-graphic(
  $arrow-min: $tablet-min,
  $colors: #35726B #037086 #1968E0 #4D4378 #A93B51,
  $hover-darken: 10%,
  $arrowhead-lighten: 15%,
  $height: 84px,
  $arrow-depth: 10px,
  $spacing: 4px,
  $arrowhead-width: 6px,
  $normal-clip: 10px,
  $hover-clip: 5px,
  $active-clip: 0) {

  padding: 0;
  font: {
    weight: bold;
    family: $open-sans;
    size: rem(14);
  }
  color: $white;
  text: {
    align: center;
    shadow: 1px 1px 1px rgba($black, 0.75);
  }

  $stack-max: $arrow-min - 1;
  $num-colors: list.length($colors);

  @for $i from 1 through $num-colors {
    $color: list.nth($colors, $i);
    $darken: -$hover-darken;
    $lighten: $arrowhead-lighten;

    & > *:nth-child(#{$num-colors}n+#{$i}) {
      --arrow-color: #{$color};
      --arrowhead-color: #{color.adjust($color, $lightness: $lighten)};
      --arrow-color-hover: #{color.adjust($color, $lightness: $darken)};
      --arrowhead-color-hover: #{color.adjust($color, $lightness: $darken + $lighten)};
    }
  }

  // li
  & > * {
    list-style: none;
    padding: 0;
    line-height: 1.3;

    // a or span
    & > * {
      background-color: $default-accent-color;
      background-color: var(--arrowhead-color);

      &::before {
        border-top-color: var(--arrowhead-color);
        border-bottom-color: var(--arrowhead-color);
      }

      &::after {
        border-left-color: var(--arrowhead-color);
      }

      & > *:not(br) {
        background-color: $default-accent-color;
        background-color: var(--arrow-color);

        &::before {
          border-top-color: var(--arrow-color);
          border-bottom-color: var(--arrow-color);
        }

        &::after {
          border-left-color: var(--arrow-color);
        }
      }
    }

    // active a or span
    &.active > *, & > a:hover, & > a:focus {
      background-color: var(--arrowhead-color-hover);

      &::before {
        border-top-color: var(--arrowhead-color-hover);
        border-bottom-color: var(--arrowhead-color-hover);
      }

      &::after {
        border-left-color: var(--arrowhead-color-hover);
      }

      & > *:not(br) {
        background-color: var(--arrow-color-hover);

        &::before {
          border-top-color: var(--arrow-color-hover);
          border-bottom-color: var(--arrow-color-hover);
        }

        &::after {
          border-left-color: var(--arrow-color-hover);
        }
      }
    }

    & > *, & > * > * {
      display: block;
    }

    & > * > * > * {
      line-height: 1.3;
    }

    a {
      &, &:visited, &:hover, &:active {
        color: $white;
        text-decoration: none;
      }

      &:focus {
        outline: none;
      }
    }
  }

  @include breakpoint($min: $arrow-min, $addPrint: true) {
    display: table;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: ($arrow-depth + $spacing) 0;
    width: calc(100% + #{$spacing});
    height: $height;
    margin: 1rem (-1 * $spacing);

    &:not(.formatted) > * > * {
      background-color: var(--arrow-color);
      padding: rem($normal-clip + 4px) rem(math.div($arrowhead-width, 2));
    }

    & > * {
      display: table-cell;
      position: relative;
      list-style: none;
      height: $height;
      margin: 0;
      padding: 0;

      &.active {
        & > * {
          clip: rect(#{$active-clip}, 900px, #{$height - $active-clip}, #{-1 * $arrow-depth});
          transition: clip 250ms;
        }
      }

      &:not(.active) > a:hover {
        clip: rect(#{$hover-clip}, 900px, #{$height - $hover-clip}, #{-1 * $arrow-depth});
        transition: clip 250ms;
      }

      & > * {
        position: absolute;
        width: 100%;
        height: $height;
        clip: rect(#{$normal-clip}, 900px, #{$height - $normal-clip}, #{-1 * $arrow-depth});
        transition: clip 250ms;
        color: $white;

        &, & > * {
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -1 * ($arrow-depth - 0.5);
            width: 0;
            height: 0;
            border: {
              top: solid math.div($height, 2);
              bottom: solid math.div($height, 2);
              left: solid $arrow-depth transparent;
            }
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: -1 * ($arrow-depth - 0.5);
            width: 0;
            height: 0;
            border: {
              top: solid math.div($height, 2) transparent;
              bottom: solid math.div($height, 2) transparent;
              left: {
                style: solid;
                width: $arrow-depth;
              }
            }
          }
        }

        & > *:not(br) {
          position: absolute;
          z-index: 1;
          width: calc(100% - #{$arrowhead-width});
          height: 100%;
          padding: 0 0 0 5px;
          line-height: $height - 2px;
          text-align: center;

          & > * {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
            overflow: hidden;
            vertical-align: middle;
          }
        }
      }

      &:first-child > * {
        left: -1 * $arrow-depth;
        width: calc(100% + #{$arrow-depth});

        &, & > * {
          &::before {
            content: none;
          }
        }
      }
    }
  }

  @include breakpoint($max: $stack-max) {
    display: block;
    margin: 1rem 0;

    &:not(.formatted) > * > * {
      background-color: var(--arrow-color);
      padding: rem(10) rem(10) rem(10px + $arrowhead-width);
    }

    & > * {
      display: block;
      margin: $spacing;

      & > * {
        padding-bottom: $arrowhead-width;

        & > * {
          padding: rem(10);
        }
      }

      &:not(.active) {
        margin: $spacing ($spacing + $normal-clip);
      }
    }
  }
}
