// Miscellaneous/catch-all content styles and patterns

@use 'sass:map';
@use 'sass:math';
@use '../../tools' as *;

// Margin adjustment classes
@for $num from 2 through 5 {
  .margin-top-#{$num}rem.margin-top-#{$num}rem.margin-top-#{$num}rem {
    margin-top: #{$num}rem;
  }
}

// Exit link disclaimers
.eld {
  font-size: 0;
  text-indent: 5000rem;

  &::before {
    font-size: 1rem;
    text-indent: 0;
  }

  @at-root .exit-no-break + &::before {
    display: inline;
  }

  &:is(.eld, :visited, :hover, :active) {
    color: var(--icon-color, #{$text-green});
    text-decoration: none;
  }
}

// Background color classes
@each $class, $color in $background-colors {
  .#{$class} {
    background-color: $color;
  }
}

// Links with an icon in front (email, phone)
%_text-icon-before {
  @extend %icon-base;
  @extend %icon-hover-fix;
  color: var(--icon-color, #{$text-general});
  padding: 0 rem(4) 0 rem(2);
}

a {
  $class-icon: (
    email: $i-envelope-o,
    phone: $i-mobile
  );

  @each $cls, $icon in $class-icon {
    &.#{$cls}::before {
      @extend %_text-icon-before;
      content: $icon;
    }
  }

  &.no-icon:is(#{join-classes(map.keys($class-icon))})::before {
    content: normal;
  }
}

%_text-icon-before-social-contact {
  @extend %_text-icon-before;
  display: block;
  position: absolute;
  padding: 0;
  font-size: 1.5em;
  top: 0;
  left: 0;
}

// Links to contact information
.social-contact-links {
  font-family: $open-sans;

  & > a {
    display: block;
    position: relative;
    max-width: 100%;
    margin-bottom: 0.8rem;
    padding-left: rem(24);

    $class-icon: (
      email: $i-envelope-square,
      phone: $i-phone-square,
      add-contact: $i-address-book,
      linkedin: $i-linkedin-square,
      researchgate: $i-researchgate-square,
      google-scholar: $i-google-scholar-square,
      orcid: $i-orcid-square
    );

    @each $cls, $icon in $class-icon {
      &.#{$cls}::before {
        @extend %_text-icon-before-social-contact;
        content: $icon;
      }
    }

    &.email {
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  @include desktop {
    & > a {
      margin-bottom: 0.5rem;
    }

    &.inline > a {
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
}

// Call-to-action links (e.g., "More ..." links with circle chevron)
:is(.cta, .dk-cta) {
  $cta-color: var(--cta-color, #{$text-general});

  display: block;
  position: relative;
  text-align: right;
  margin-top: 1rem;
  padding-right: 1.3em;
  font-family: $open-sans;
  color: $cta-color;

  &:where(a) {
    text-decoration: none;

    &:is(:link, :visited, :hover, :active) {
      color: $cta-color;
    }

    &:is(:hover, :active) {
      text-decoration: underline;

      &::after {
        @extend %icon-hover-fix;
      }
    }
  }

  &::after {
    @include icon-base;
    content: $i-arrow-right;
    position: absolute;
    top: calc(50% - 0.5em);
    right: 0;
    color: inherit;
    vertical-align: middle;
    text-decoration: none;
  }
}

// "Action Links" (i.e., chevron interrupters)
.action-link {
  display: block;
  position: relative;
  text-align: left;
  margin: rem(14) 0;
  color: $text-dark;
  line-height: 1.6;

  &, &.thin-box {
    padding: 1rem 0.5rem 1rem rem(28);
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: rem(28);
    height: rem(22);
    padding: 0;
    transform: translateY(-50%);
    font-size: 1.6rem;
    text-align: center;

    @extend %double-chevron-right;
  }

  a:not(.no-arrow)::after, &.end-arrow::after {
    color: $text-general;
    vertical-align: middle;
    text-decoration: none;
    margin-top: rem(-2);

    @extend %chevron-circle-right;
    @extend %icon-hover-fix;
  }

  &.end-arrow a::after {
    content: normal;
  }

  &.no-padding {
    @include boost-specificity {
      padding-left: rem(28); // Ironically add padding to accommodate the double chevron
    }
  }

  .eld {
    text-indent: 0;
  }
}

// Breakpoint-conditional display
@include mobile {
  .hide-mobile.hide-mobile.hide-mobile.hide-mobile {
    display: none;
  }

  i.hide-mobile {
    font-style: normal;
  }
}

// Caption and disclaimer paragraphs with smaller text
:is(.disclaimer, .caption) p {
  font-size: .8rem;
}

.caption p {
  font-family: $open-sans;
}

// Text for abstracts
.abstract, p.abstract {
  font-weight: bold;
  line-height: 1.5;
}

// Columns of photos, names and titles
// Used for Advisory & Coordinating Committees: Members pages
.person-columns {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;

  & > * {
    margin-bottom: 1.5rem;

    strong a i {
      display: inline-block;
      margin-left: 0.3rem;

      color: $text-general;
    }

    a.email .i-envelope-o {
      display: none;
    }

    img {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &::after {
    content: '';
  }

  & > *, &::after {
    width: 45%;

    @include desktop {
      width: 32%;
    }
  }

  @include clearfix;
}

// Fixed aspect ratio
.ratio {
  @extend %constrained-box;

  &.r1-1, &.square {
    @include ratio(1, 1);
  }

  &.r4-3 {
    @include ratio(4, 3);
  }

  &.r12-7, &.blog-image {
    @include ratio(12, 7);
  }

  &.r16-9, &.youtube {
    @include ratio(16, 9);
  }

  &.golden {
    @include ratio(1 + math.sqrt(5), 2);
  }
}

// YouTube player container
.youtube-container {
  @extend %constrained-box;
  width: rem(400);

  @include ratio(16, 9);

  &.full-width {
    width: 100%;

    & + textarea[readonly] {
      width: 100%;
    }
  }

  &.half {
    width: 50%;

    & + textarea[readonly] {
      width: 50%;
    }

    @include mobile {
      width: 100%;

      & + textarea[readonly] {
        width: 100%;
      }
    }
  }

  & + textarea[readonly] {
    display: block;
    width: rem(400);
    margin: 0 0 1rem;
  }
}

// Content "tag"
.dk-tag {
  @extend %dk-tag;
  margin-right: 0.25rem;
  font-weight: $semibold;
  line-height: 1.6;

  &.updated {
    background-color: $orange; //#a66818;
  }

  &.new {
    background-color: $green; //#607f3c;
  }
}

// Feature boxes with an image to the left of non-wrapping content (e.g., Clinical Tools & Patient Management landing, NIHOR Strat. Plan)
.left-image {
  &, * {
    @extend %remove-child-bookend-margins;
  }

  &.left-image.left-image {
    h2 {
      margin: 1rem 0;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    img {
      display: block;
      margin: 0;
      border: 0;
    }
  }

  .shadow img {
    box-shadow: 0 rem(1.5) rem(5) rgba($black, 0.13);
  }

  @include desktop(true) {
    display: flex;
    align-items: flex-start;

    & > :first-child {
      flex: 0 0 auto;
      max-width: calc(30% - 1rem);
      height: auto;
      margin-right: 1rem;

      img {
        width: 100%;
      }
    }

    & > :last-child {
      flex: 1 1 auto;
    }
  }

  @include mobile {
    & > :first-child:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.float-mobile {
      @include clearfix;

      & > :first-child {
        float: left;
        max-width: calc(50% - 1rem);
        margin: rem(5) 1rem 0.5rem 0;
      }
    }
  }
}
