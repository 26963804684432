@use "variables" as *;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2') format("woff2"),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-arrow-left {
  &:before {
    content: $i-arrow-left; 
  }
}
.i-arrow-right {
  &:before {
    content: $i-arrow-right; 
  }
}
.i-arrow-up {
  &:before {
    content: $i-arrow-up; 
  }
}
.i-arrow-down {
  &:before {
    content: $i-arrow-down; 
  }
}
.i-chevron-left {
  &:before {
    content: $i-chevron-left; 
  }
}
.i-chevron-right {
  &:before {
    content: $i-chevron-right; 
  }
}
.i-chevron-up {
  &:before {
    content: $i-chevron-up; 
  }
}
.i-chevron-down {
  &:before {
    content: $i-chevron-down; 
  }
}
.i-triangle-left {
  &:before {
    content: $i-triangle-left; 
  }
}
.i-triangle-right {
  &:before {
    content: $i-triangle-right; 
  }
}
.i-triangle-up {
  &:before {
    content: $i-triangle-up; 
  }
}
.i-triangle-down {
  &:before {
    content: $i-triangle-down; 
  }
}
.i-play {
  &:before {
    content: $i-play; 
  }
}
.i-pause {
  &:before {
    content: $i-pause; 
  }
}
.i-stop {
  &:before {
    content: $i-stop; 
  }
}
.i-exit {
  &:before {
    content: $i-exit; 
  }
}
.i-exit-nih {
  &:before {
    content: $i-exit-nih; 
  }
}
.i-top-en {
  &:before {
    content: $i-top-en; 
  }
}
.i-top-es {
  &:before {
    content: $i-top-es; 
  }
}
.i-menu {
  &:before {
    content: $i-menu; 
  }
}
.i-share {
  &:before {
    content: $i-share; 
  }
}
.i-address-book {
  &:before {
    content: $i-address-book; 
  }
}
.i-blog {
  &:before {
    content: $i-blog; 
  }
}
.i-mobile {
  &:before {
    content: $i-mobile; 
  }
}
.i-video {
  &:before {
    content: $i-video; 
  }
}
.i-audio {
  &:before {
    content: $i-audio; 
  }
}
.i-shape-circle {
  &:before {
    content: $i-shape-circle; 
  }
}
.i-shape-hexagon {
  &:before {
    content: $i-shape-hexagon; 
  }
}
.i-shape-pentagon-arrow {
  &:before {
    content: $i-shape-pentagon-arrow; 
  }
}
.i-shape-square {
  &:before {
    content: $i-shape-square; 
  }
}
.i-google-scholar-square {
  &:before {
    content: $i-google-scholar-square; 
  }
}
.i-orcid-square {
  &:before {
    content: $i-orcid-square; 
  }
}
.i-researchgate-square {
  &:before {
    content: $i-researchgate-square; 
  }
}
.i-researchgate {
  &:before {
    content: $i-researchgate; 
  }
}
.i-cells {
  &:before {
    content: $i-cells; 
  }
}
.i-collaboration {
  &:before {
    content: $i-collaboration; 
  }
}
.i-double-chevron {
  &:before {
    content: $i-double-chevron; 
  }
}
.i-examine-report {
  &:before {
    content: $i-examine-report; 
  }
}
.i-funding {
  &:before {
    content: $i-funding; 
  }
}
.i-grant-apply {
  &:before {
    content: $i-grant-apply; 
  }
}
.i-grant-award {
  &:before {
    content: $i-grant-award; 
  }
}
.i-grant-examine {
  &:before {
    content: $i-grant-examine; 
  }
}
.i-grant-fund {
  &:before {
    content: $i-grant-fund; 
  }
}
.i-grant-funding {
  &:before {
    content: $i-grant-funding; 
  }
}
.i-grant-manage {
  &:before {
    content: $i-grant-manage; 
  }
}
.i-grant-review {
  &:before {
    content: $i-grant-review; 
  }
}
.i-handshake {
  &:before {
    content: $i-handshake; 
  }
}
.i-node-cluster {
  &:before {
    content: $i-node-cluster; 
  }
}
.i-newspaper {
  &:before {
    content: $i-newspaper; 
  }
}
.i-new-investigator {
  &:before {
    content: $i-new-investigator; 
  }
}
.i-mortarboard {
  &:before {
    content: $i-mortarboard; 
  }
}
.i-medical-microscope {
  &:before {
    content: $i-medical-microscope; 
  }
}
.i-lightbulb {
  &:before {
    content: $i-lightbulb; 
  }
}
.i-id-badge {
  &:before {
    content: $i-id-badge; 
  }
}
.i-person-presenting {
  &:before {
    content: $i-person-presenting; 
  }
}
.i-small-business {
  &:before {
    content: $i-small-business; 
  }
}
.i-tools {
  &:before {
    content: $i-tools; 
  }
}
.i-dna {
  &:before {
    content: $i-dna; 
  }
}
.i-test-tubes {
  &:before {
    content: $i-test-tubes; 
  }
}
.i-robotic-arm {
  &:before {
    content: $i-robotic-arm; 
  }
}
.i-clipboard {
  &:before {
    content: $i-clipboard; 
  }
}
.i-molecule {
  &:before {
    content: $i-molecule; 
  }
}
.i-mouse {
  &:before {
    content: $i-mouse; 
  }
}
.i-mortar-pestle {
  &:before {
    content: $i-mortar-pestle; 
  }
}
.i-people-discussing {
  &:before {
    content: $i-people-discussing; 
  }
}
.i-additional-reading {
  &:before {
    content: $i-additional-reading; 
  }
}
.i-toolbox {
  &:before {
    content: $i-toolbox; 
  }
}
.i-weight-management {
  &:before {
    content: $i-weight-management; 
  }
}
.i-school-responsibilities {
  &:before {
    content: $i-school-responsibilities; 
  }
}
.i-risk-factors {
  &:before {
    content: $i-risk-factors; 
  }
}
.i-checklists {
  &:before {
    content: $i-checklists; 
  }
}
.i-blood-screening {
  &:before {
    content: $i-blood-screening; 
  }
}
.i-diabetes {
  &:before {
    content: $i-diabetes; 
  }
}
.i-download-file {
  &:before {
    content: $i-download-file; 
  }
}
.i-kidney-disease {
  &:before {
    content: $i-kidney-disease; 
  }
}
.i-clinical-trials {
  &:before {
    content: $i-clinical-trials; 
  }
}
.i-publications {
  &:before {
    content: $i-publications; 
  }
}
.i-research-materials {
  &:before {
    content: $i-research-materials; 
  }
}
.i-repository {
  &:before {
    content: $i-repository; 
  }
}
.i-lab-software {
  &:before {
    content: $i-lab-software; 
  }
}
.i-lab-servers {
  &:before {
    content: $i-lab-servers; 
  }
}
.i-pulse-programs {
  &:before {
    content: $i-pulse-programs; 
  }
}
.i-standards {
  &:before {
    content: $i-standards; 
  }
}
.i-webinar-s {
  &:before {
    content: $i-webinar-s; 
  }
}
.i-pos-block-left {
  &:before {
    content: $i-pos-block-left; 
  }
}
.i-pos-block-center {
  &:before {
    content: $i-pos-block-center; 
  }
}
.i-pos-float-left {
  &:before {
    content: $i-pos-float-left; 
  }
}
.i-pos-float-right {
  &:before {
    content: $i-pos-float-right; 
  }
}
.i-size-small {
  &:before {
    content: $i-size-small; 
  }
}
.i-size-medium {
  &:before {
    content: $i-size-medium; 
  }
}
.i-size-large {
  &:before {
    content: $i-size-large; 
  }
}
.i-size-fill {
  &:before {
    content: $i-size-fill; 
  }
}
.i-webinar {
  &:before {
    content: $i-webinar; 
  }
}
.i-chat-bubble {
  &:before {
    content: $i-chat-bubble; 
  }
}
.i-live-chat-o {
  &:before {
    content: $i-live-chat-o; 
  }
}
.i-live-chat {
  &:before {
    content: $i-live-chat; 
  }
}
.i-facebook-c {
  &:before {
    content: $i-facebook-c; 
  }
}
.i-twitter-c {
  &:before {
    content: $i-twitter-c; 
  }
}
.i-youtube-c {
  &:before {
    content: $i-youtube-c; 
  }
}
.i-instagram {
  &:before {
    content: $i-instagram; 
  }
}
.i-linkedin-c {
  &:before {
    content: $i-linkedin-c; 
  }
}
.i-x-social-c {
  &:before {
    content: $i-x-social-c; 
  }
}
.i-quote-open {
  &:before {
    content: $i-quote-open; 
  }
}
.i-quote-close {
  &:before {
    content: $i-quote-close; 
  }
}
.i-copy {
  &:before {
    content: $i-copy; 
  }
}
.i-angle-left {
  &:before {
    content: $i-angle-left; 
  }
}
.i-angle-right {
  &:before {
    content: $i-angle-right; 
  }
}
.i-angle-up {
  &:before {
    content: $i-angle-up; 
  }
}
.i-angle-down {
  &:before {
    content: $i-angle-down; 
  }
}
.i-angle-double-left {
  &:before {
    content: $i-angle-double-left; 
  }
}
.i-angle-double-right {
  &:before {
    content: $i-angle-double-right; 
  }
}
.i-angle-double-up {
  &:before {
    content: $i-angle-double-up; 
  }
}
.i-angle-double-down {
  &:before {
    content: $i-angle-double-down; 
  }
}
.i-thick-left {
  &:before {
    content: $i-thick-left; 
  }
}
.i-thick-right {
  &:before {
    content: $i-thick-right; 
  }
}
.i-thick-up {
  &:before {
    content: $i-thick-up; 
  }
}
.i-thick-down {
  &:before {
    content: $i-thick-down; 
  }
}
.i-plus {
  &:before {
    content: $i-plus; 
  }
}
.i-minus {
  &:before {
    content: $i-minus; 
  }
}
.i-times {
  &:before {
    content: $i-times; 
  }
}
.i-download {
  &:before {
    content: $i-download; 
  }
}
.i-print {
  &:before {
    content: $i-print; 
  }
}
.i-spinner {
  &:before {
    content: $i-spinner; 
  }
}
.i-check {
  &:before {
    content: $i-check; 
  }
}
.i-exclamation-circle {
  &:before {
    content: $i-exclamation-circle; 
  }
}
.i-question-circle {
  &:before {
    content: $i-question-circle; 
  }
}
.i-search {
  &:before {
    content: $i-search; 
  }
}
.i-search-plus {
  &:before {
    content: $i-search-plus; 
  }
}
.i-search-minus {
  &:before {
    content: $i-search-minus; 
  }
}
.i-clock-o {
  &:before {
    content: $i-clock-o; 
  }
}
.i-map-marker {
  &:before {
    content: $i-map-marker; 
  }
}
.i-calendar {
  &:before {
    content: $i-calendar; 
  }
}
.i-calendar-o {
  &:before {
    content: $i-calendar-o; 
  }
}
.i-tty {
  &:before {
    content: $i-tty; 
  }
}
.i-envelope {
  &:before {
    content: $i-envelope; 
  }
}
.i-envelope-o {
  &:before {
    content: $i-envelope-o; 
  }
}
.i-facebook {
  &:before {
    content: $i-facebook; 
  }
}
.i-x-social {
  &:before {
    content: $i-x-social; 
  }
}
.i-twitter {
  &:before {
    content: $i-twitter; 
  }
}
.i-linkedin {
  &:before {
    content: $i-linkedin; 
  }
}
.i-rss {
  &:before {
    content: $i-rss; 
  }
}
.i-envelope-square {
  &:before {
    content: $i-envelope-square; 
  }
}
.i-facebook-square {
  &:before {
    content: $i-facebook-square; 
  }
}
.i-twitter-square {
  &:before {
    content: $i-twitter-square; 
  }
}
.i-x-social-square {
  &:before {
    content: $i-x-social-square; 
  }
}
.i-linkedin-square {
  &:before {
    content: $i-linkedin-square; 
  }
}
.i-rss-square {
  &:before {
    content: $i-rss-square; 
  }
}
.i-phone-square {
  &:before {
    content: $i-phone-square; 
  }
}
.i-user-medical {
  &:before {
    content: $i-user-medical; 
  }
}
.i-gear {
  &:before {
    content: $i-gear; 
  }
}
.i-whatsapp {
  &:before {
    content: $i-whatsapp; 
  }
}
.i-reddit {
  &:before {
    content: $i-reddit; 
  }
}
.i-pinterest {
  &:before {
    content: $i-pinterest; 
  }
}

