@use 'sass:math';
@use '../../tools' as *;

// Classes to support splitting body content into columns

// Columns of content
%content-columns {
  $widths: (
    one-quarter: 3,
    one-third: 4,
    one-half: 6,
    two-thirds: 8,
    three-quarters: 9
  );

  margin: 1rem 0;
  @include row;

  & > :is(ul, ol) {
    margin: {
      top: 0;
      bottom: 0;
    }

    @include mobile {
      &:not(:first-child) {
        margin-top: 0.5em;
      }

      &:not(:last-child) {
        margin-bottom: 0.5em;
      }
    }
  }

  @include desktop {
    & > :is(ul, ol) {
      & > li:first-of-type {
        margin-top: 0;
      }

      & > li:last-of-type {
        margin-bottom: 0;
      }
    }

    & > * {
      margin: {
        top: 0;
        bottom: 0;
      }

      @include remove-bookend-margins(child);
    }

    @for $i from 2 through 4 {
      & > :first-child:nth-last-child(#{$i}) {
        &, & ~ * {
          $cols: math.div(12, $i);

          @include span-columns($cols of 12);
        }
      }
    }

    @each $class, $cols in $widths {
      & > .#{$class}.#{$class}.#{$class} {
        @include span-columns($cols of 12);
      }
    }
  }

  @include mobile {
    @each $class, $cols in $widths {
      & > .#{$class}.#{$class}.#{$class} {
        @include span-columns(12 of 12);
      }
    }
  }
}

.columns {
  @extend %content-columns;
}

// Wrapping columns
.column-wrap {
  break-inside: avoid-column;
  columns: 2;

  @each $i in (3, 4) {
    &.cols-#{$i} {
      columns: $i;
    }
  }

  @include mobile {
    &:not(.wrap-mobile) {
      columns: 1;
    }
  }

  &.margin {
    margin: 1rem 0;
  }

  &.no-break > *, &:not(.allow-break) > li {
    page-break-inside: avoid;
  }

  & > p {
    @include remove-bookend-margins;
  }
}

:is(ul, ol).column-wrap.loose {
  @include remove-bookend-margins(child);
}
