// NIDDK site structural styling

@use 'sass:color';
@use 'sass:math';
@use '../../../tools' as *;

// main content area (kitchen-sink)
.dk-two-column .dk-breadcrumb-row {
  min-height: rem(30);
}

.site-wrap .page-mask .sn-close-nav {
  visibility: hidden;
}

.box-search {
  margin: 1rem 0;

  @include desktop {
    @at-root .dk-box &, .thin-box & {
      margin: 1rem;
    }
  }

  .search {
    @extend %standard-search-box;
  }

  .description {
    margin-top: 0.4rem;
    font: $semibold rem(12) $open-sans;
  }
}

// Language-specific content
@each $lang in $page-languages {
  body {
    &:not(.page-lang-#{$lang}) .lang-#{$lang},
    &.page-lang-#{$lang} .not-lang-#{$lang} {
      display: none;
    }
  }
}

// Contact Us buttons
.contact-us-buttons {
  .chat.online {
    display: none;
  }

  .button {
    display: block;
    min-width: 90%;
    font-size: 1.4rem;
    text-align: center;
    cursor: pointer;

    &.chat {
      &.online {
        background-color: $green;
        border-color: $green;

        &:hover {
          $darker: color.adjust($green, $lightness: -10%);

          background-color: $darker;
          border-color: $darker;
        }
      }

      &.offline {
        @extend %button;
        display: block;
        $gray: get-accent-color('gray', $prop: 'background-color');

        background-color: $gray;
        border-color: $gray;
        font-size: 1.4rem;

        &:hover {
          &:where(a) {
            $darker: color.adjust($gray, $lightness: -10%);

            background-color: $darker;
            border-color: $darker;
          }

          &:where(span) {
            background-color: $gray;
            border-color: $gray;
            cursor: default;
          }
        }
      }
    }

    &.email {
      $_lavender: get-accent-color('lavender', $prop: 'background-color');
      background-color: $_lavender;
      border-color: $_lavender;

      &:hover {
        $darker: color.adjust($_lavender, $lightness: -10%);

        background-color: $darker;
        border-color: $darker;
      }
    }

    &.tty {
      background-color: $orange;
      border-color: $orange;

      &:hover {
        $darker: color.adjust($orange, $lightness: -10%);

        background-color: $darker;
        border-color: $darker;
      }
    }


    & > i {
      margin-right: 0.8rem;
    }

    @include mobile {
      width: 100%;
    }
  }

  @at-root .livechat-online & {
    .chat {
      &.online {
        &.button {
          display: block;
        }
        &:not(.button) {
          display: inline;
        }
      }

      &.offline {
        display: none;
      }
    }
  }

  @include tablet {
    &.columns > div:first-child, &.columns > div:last-child {
      width: 100%;

      .button {
        min-width: rem(225);
        max-width: rem(300);
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
      }
    }
  }
}

.twitter-follow-wrap {
  margin: 1rem 0;
}

// Small image beside link (e.g., health info landing "For Health Professionals")
.image-beside-link {
  display: table;
  width: calc(100% + #{rem(30)});
  margin: rem(-15);
  table-layout: fixed;
  border-spacing: rem(15);
  font-size: rem(18);
  font-weight: $semibold;

  @include mobile {
    font-size: rem(16);
  }

  & > * {
    display: table-cell;
    vertical-align: middle;
  }

  & > :first-child {
    width: 30%;

    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      margin: 0 0 0 0;
    }
  }

  & > :last-child {
    width: 70%;
  }
}

// Roll-up of pages with images, title, and short description
%page-rollup-item {
  list-style: none;
  margin: 1rem 0;
  padding: 0;

  & > * > * {
    @extend %remove-bookend-margins;
  }

  .title {
    margin: 1rem 0;
    font-weight: bold;
    font-family: $open-sans;
  }
}

%page-rollup {
  margin: 1rem 0;

  & > * {
    @extend %page-rollup-item;

    &:not(:last-child) {
      padding-bottom: 1rem;
      border-bottom: solid 1px $divider-stroke;
    }
  }

  & + %page-rollup, & + h2, & + h3 {
    margin-top: 2.5rem;
  }
}

.page-rollup {
  @extend %page-rollup;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @include desktop {
    & > * {
      @include row;

      & > :first-child:nth-last-child(2) {
        @include span-columns(5 of 12);
      }

      & > :last-child:nth-child(2) {
        @include span-columns(7 of 12);
      }

      .title {
        margin-top: 0;
      }
    }

    &.meet-director > * {
      @include row;

      & > :first-child:nth-last-child(2) {
        padding-top: rem(5);

        @include span-columns(3.5 of 12);
      }

      & > :last-child:nth-child(2) {
        @include span-columns(8.5 of 12);
      }
    }
  }
}

.youtube-rollup {
  @extend %page-rollup;
}

.youtube-rollup > *, .youtube-summary {
  @extend %page-rollup-item;

  & > :first-child:nth-last-child(2) {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: (math.div(22, 40) * 100%);
    }

    iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@include desktop {
  .youtube-summary.columns, .youtube-rollup:not(.stack) > :not(.stack) {
    @include row;

    & > :first-child:nth-last-child(2), & > :last-child:nth-child(2) {
      @include span-columns(6 of 12);
    }

    .title {
      margin-top: 0;
    }
  }
}

// TODO: eval usage; possible unnecessary
.header-with-link {
  margin: 1rem 0;

  @include row;

  h2 {
    float: left;
    margin: 0 1rem 0 0;
  }

  a, a.cta {
    display: block;
    float: right;
    line-height: rem(33);
    margin: 0;
  }
}
