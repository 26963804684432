@use 'sass:color';
@use '../../tools' as *;

// Content folding (hide some content beneath a "More" button)
%foldable-container {
  .fold {
    display: block;
    clear: both;
    cursor: pointer;

    @at-root {
      html:not(.editmode) & ~ :not(.unfold) {
        display: none;
      }

      html:not(.editmode) & ~ .unfold ~ * {
        display: initial;

        &.cta {
          display: block;
        }
      }
    }
  }

  .no-outline:focus {
    outline: none;
  }
}

.foldable {
  @extend %foldable-container;
}

// Foldable "Box" elements (e.g., Intramural "About Our Research" pages)
.box {
  @extend %foldable-container;

  .fold {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0;
    background-color: $bg-gray;
    font-family: $open-sans;
    font-size: 1.25rem;
    text-align: center;

    &:hover {
      background-color: color.adjust($bg-gray, $lightness: -10%);
    }
  }
}
