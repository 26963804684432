@use 'sass:color';
@use 'sass:map';
@use '../../../tools' as *;

.pt-blog {
  @include mobile {
    .dk-breadcrumb-row {
      display: none;
    }

    .dk-main-container {
      margin-top: 0;
    }
  }

  .dk-main-container .dk-content > article {
    border: 0;
  }

  a.blog-title {
    &:is(:link, :visited, :hover) {
      color: $white;
      text-decoration: none;
    }

    &:hover {
      .blog-icon {
        background-color: $link-visited;
      }

      svg .inner {
        fill: $link-visited;
      }
    }
  }

  .blog-title {
    display: table;
    table-layout: fixed;
    width: calc(100% + #{rem(40)});
    margin: 0 rem(-20); // Counteract dk-main-container
    padding: 0 rem(6);
    border-spacing: 1rem 0.8rem;
    background-image: url(../images/bg-blog-title.png);
    background-position: top left;
    background-size: cover;
    color: $white;
    @extend %size-h1;
    @extend %accent-background;

    & > * {
      display: table-cell;
      vertical-align: middle;
    }

    .title {
      margin: 0;
      text-shadow: 1px 1px $text-dark;
      @extend %size-h1;
      font-family: $open-sans;
      font-weight: $semibold;
      line-height: 1.3;
    }

    .icon, svg {
      width: rem(50);
    }

    svg {
      display: block;
      fill: $white;
      height: rem(35);

      .inner {
        fill: transparent;
      }
    }

    @include mobile {
      width: calc(100% + #{rem(20)});
      margin: 0 rem(-10); // Counteract dk-main-container
      padding: 0 rem(10);

      .title {
        font-size: 1.4rem;
      }

      .icon, svg {
        width: rem(40);
      }

      svg {
        height: rem(30);
      }
    }
  }

  .feed-summary {
    @extend %remove-child-bookend-margins;
    margin: 1rem 0 2rem;
    padding: 0.5rem;
    background-color: $bg-gray;
  }

  article {
    max-width: rem(600);

    & + article {
      margin-top: 3rem;
    }

    h2 {
      a {
        &, &:visited {
          text-decoration: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      & ~ .byline {
        margin-top: -0.5rem;
      }

      @include mobile {
        font-size: 1.3rem;
      }
    }

    header {
      font-family: $open-sans;
      font-weight: $semibold;

      h2 {
        @extend %size-h2;
      }

      .details {
        margin: 1rem 0;
        padding-top: 1rem;
        border-top: solid 2px $divider-stroke;

        @include row;

        & > * {
          @include row;
        }

        & > :first-child {
          & > * {
            display: block;
            float: left;
            max-width: 45%;
          }

          & > :nth-child(2) {
            float: right;
          }
        }

        .tags {
          margin: rem(3) 0 0;
          list-style: none;
          font-size: 0;

          li {
            display: inline-block;
            margin: 0;
            font-size: 1rem;

            &:first-child::before {
              content: 'Tagged: ';
              display: inline-block;
              margin: 0 3px 0 0;
            }

            &:not(:last-child)::after {
              content: '/';
              display: inline-block;
              margin: 0 3px;
            }
          }
        }
      }
    }

    &.summary header .tags {
      display: none;
    }

    .main-image {
      position: relative;

      .corner {
        @extend %video-corner;

        .crn-bg {
          fill: $default-accent-color;
          fill: var(--accent-bg-color, #{$default-accent-color});
        }
      }
    }

    .leader {
      font-weight: bold;

      .more {
        font-weight: normal;

        &::after {
          content: $i-arrow-right;
          display: inline-block;
          font-family: $dk-icons;
          margin: -1px 0 0 5px;
          color: $text-general;
          vertical-align: middle;
          text-decoration: none;

          @include icon-hover-fix;
        }
      }
    }

    @for $i from 1 through 5 {
      h#{$i} {
        @extend %size-h#{$i + 1};
      }
    }

    img {
      max-width: 100%;
    }

    .about-author {
      position: relative;
      margin: 2rem 0;
      font-family: $open-sans;
      min-height: rem(115);

      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: rem(5);
        max-width: rem(100);
        max-height: rem(100);
        border-radius: 50%;
        margin: rem(5) rem(10) rem(10) 0;
      }

      & > img ~ :not(img) {
        margin-left: rem(110);
      }

      & > :not(img), p {
        margin: 0.5rem 0;
      }

      & > :last-child, p:last-child {
        margin-bottom: 0;
      }

      & > div {
        line-height: 1.8rem;
      }

      :is(h1, h2) {
        @extend %size-h6;
        margin-top: 0;

        &[id]::before {
          content: initial;
        }
      }

      a {
        font-weight: $semibold;
      }

      @include mobile {
        img {
          position: static;
          float: left;
          max-width: rem(80);
          max-height: rem(80);
        }

        & > img ~ :not(img) {
          margin-left: 0;
        }
      }
    }

    .subscribe-share {
      margin: 2.5rem 0;

      @include desktop
      {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      @include mobile {
        & > * {
          margin: 2rem 0;
          text-align: center;
        }

        .dk-share {
          justify-content: center;
        }
      }

      .subscribe button {
        $color: color.adjust($green, $lightness: 20%);

        display: inline-block;
        background: transparent none;
        vertical-align: middle;
        border-style: solid;
        border-width: 1px;
        border-radius: rem(3);
        padding: rem(5) 1rem;
        font-weight: bold;
        font-family: $open-sans;
        font-size: rem(16.5);
        cursor: pointer;
        -webkit-appearance: none;

        @extend %accent-border;
        @extend %accent-foreground;

        &:hover {
          color: $white;
          @extend %accent-background;
        }
      }

      .dk-share {
        margin: rem(3) 0;
      }
    }

    .related-posts {
      @include row;

      h2[id] {
        &::before {
          content: initial;
        }
      }

      article {
        margin: 0 0 2rem;

        @include desktop
        {
          @include span-columns(4 of 8);

          &:nth-of-type(2n+1) {
            clear: left;
          }
        }

        h3 {
          font-size: 1rem;
          margin: 0.5rem 0;

          &.line-clamp {
            @include line-clamp-grow(2);
          }

          a {
            color: $link-standard;

            &:visited {
              color: $link-visited;
            }

            &:hover {
              color: $link-hover;
            }
          }
        }

        .main-image {
          @extend %constrained-box;
          @include ratio(12, 7);

          & > img {
            @extend %constrained-box-centered-child;
            max-width: none;
            max-height: 100%;
          }
        }
      }
    }

    // Styles for Disqus lazy loading
    #disqus_thread {
      min-height: rem(40);

      .disqus-load {
        display: block;
        visibility: hidden;

        &.shown {
          visibility: visible;
        }

        & + .disqus-loading {
          display: none;
        }
      }

      .disqus-load, .disqus-loading {
        font-family: $open-sans;
        font-size: 1.5rem;
        text-align: center;
      }

      .disqus-loading .i-spinner {
        display: inline-block;
        margin-right: 1rem;
        font-size: 2rem;
        vertical-align: middle;
        animation: rotate 1.2s linear infinite;
      }
    }
  }

  .main-image {
    margin: 1rem 0;
    max-width: 100%;

    img {
      display: block;
      margin: 0;
      max-width: 100%;
    }

    figcaption {
      background-color: transparent;
      font-size: rem(12);
      margin-top: rem(3);
      padding: 0;
      line-height: 1.2;
      text-align: right;

      p {
        @extend %remove-bookend-margins;
        line-height: 1.2;
      }
    }
  }

  .dk-content {
    @include desktop {
      padding: {
        top: 0;
        left: 0;
        right: 0;
      }

      @include span-columns(4 of 6);
    }
  }

  .dk-blog-rail {
    @include desktop {
      margin: 2rem 0;
      padding-top: 6.5rem;

      @include span-columns(2 of 6);

      section.subscribe + section.dk-share {
        margin-top: 2.5rem;
      }
    }

    @include mobile {
      clear: both;
      padding: 0 1rem;
    }

    section {
      font-family: $open-sans;

      &:not(.dk-share):not(.subscribe) {
        margin: 2.5rem 0;

        &:last-child {
          margin-bottom: 0;
        }

        h3 {
          @extend %size-h6;
          font-weight: bold;
          margin: 0 0 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: solid 1px $divider-stroke;
        }

        strong, a {
          font-weight: $semibold;
        }

        .logo-heading {
          position: relative;
          display: table;
          width: 100%;
          margin: 0 0 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: solid 1px $divider-stroke;

          & > * {
            display: table-cell;
            vertical-align: bottom;
          }

          h3 {
            border: 0;
            margin: 0;
            padding: 0;
          }

          img {
            width: auto;
            height: auto;
            max-width: rem(70);
            max-height: rem(40);
          }

          & > :last-child {
            text-align: right;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
      }
    }

    .dk-share {
      margin: 0 0 2.5rem;
    }

    .search {
      form {
        @include row;

        & > * {
          display: block;
          float: left;
          border: 1px solid $button-outline;
          font-family: $montserrat;
          font-size: rem(12.5);
          color: $text-general;
        }

        input {
          width: calc(100% - #{rem(60)});
          padding: 0.75rem 1rem;
          border-radius: rem(3) 0 0 rem(3);
          -webkit-appearance: none;

          &::placeholder {
            color: color.mix(#fff, $button-text, 25%);
          }

          &:focus {
            outline: none;
          }
        }

        button {
          width: rem(60);
          padding: 0.75rem rem(3);
          border: {
            left: 0;
            radius: 0 rem(3) rem(3) 0;
          }
          background: $button-gray;

          &:hover {
            background: color.mix(#000, $button-gray, 10%);
            cursor: pointer;
          }
        }
      }
    }

    .tags, .archive {
      a {
        font-weight: $semibold;
      }

      .filter {
        @extend %remove-bookend-margins;
        margin: 0.5rem 0;
      }

      .fold, .folded {
        width: 100%;
        padding: 0.25rem 0.5rem;
        background-color: $button-gray;
        text-align: center;

        &::after {
          @extend %icon-hover-fix;
          @extend %icon-base;
          padding-left: 0.3rem;
        }

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .fold::after {
        content: $i-angle-down;
      }

      .folded::after {
        content: $i-angle-up;
      }

      .count {
        font-weight: $semibold;
        &::before { content: '('; }
        &::after { content: ')'; }
      }
    }

    .rowed-columns {
      &, .folded-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min-content;
      }

      .filter {
        margin: 0 0 0.5rem;
      }

      .fold, .folded, .folded-content {
        grid-column: 1 / span 2;
      }
    }

    @include mobile {
      .archive .columns > * {
        @include span-columns(6 of 12);
      }
    }

    .disclaimer, .search {
      h3 { display: none; }
    }

    .disclaimer {
      border-top: solid 1px $divider-stroke;

      p {
        font-size: rem(12);
        line-height: 2;
      }

      a {
        font-weight: normal;
      }
    }
  }

  // Accent color special cases
  .dk-main-container {
    $light-colors: (
      'lavender-dark': get-accent-color('lavender'),
      'teal-dark': get-accent-color('teal'),
      'mauve-dark': get-accent-color('mauve')
    );

    @each $name in map.keys($light-colors) {
      $color-adj: map.get($light-colors, $name);

      &.#{$accent-color-prefix}#{$name} {
        .subscribe-share .subscribe button,
        .dk-accdn .acc-main {
          --accent-border-color: #{$color-adj};
        }

        .dk-accdn .acc-main {
          --accent-fg-color: #{$color-adj};
        }
      }
    }
  }

  .fs-badge, .fs-int-question, .fs-int-buttons {
    color: $white;
    font-family: $open-sans;
  }

  .fs-int-container {
    margin: 3rem 0;
    border-left: rem(10) solid get-accent-color('teal-dark');
    background-color: $link-visited;

    .fs-int-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-left: rem(2);
      padding: 1rem 1rem;

      .fs-int-question > p {
        max-width: rem(160);
        margin: 0;
        font-size: 1rem;
        font-weight: $semibold;
        text-align: center;
        line-height: 1.33;
      }

      .fs-int-buttons {
        min-width: rem(225);
        text-align: center;

        button {
          @extend %button-ghost;
          cursor: pointer;
          width: rem(100);
          padding: .5rem;
          font-weight: $semibold;

          &:hover {
            background-color: $white;
            color: #A6481D;
          }

          &:first-child {
            margin-right: .5rem;
          }

          &::after {
            display: none;
          }
        }
      }

      @include mobile(true) {
        flex-direction: column;

        .fs-int-question > p {
          max-width: 100%;
          margin-bottom: 1rem;
        }
      }
    }

    &.v2 {
      border-left-color: $link-visited;
      background-color: get-accent-color('teal-dark');

      .fs-int-buttons > button:hover {
        background-color: $link-visited;
        color: $white;
      }
    }
  }

  .fs-badge {
    @extend %button;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: .75rem;
    border: none;
    border-radius: rem(5);
    font-family: $open-sans;
    font-weight: $semibold;
    text-align: center;
    background-color: get-accent-color('green');

    span {
      vertical-align: middle;

      &::before {
        margin-right: .5rem;
        font-size: 1.5rem;
      }
    }

    &:hover {
      background-color: color.adjust(get-accent-color('green'), $lightness: -10%);
    }
  }

  .fs-badge-error {
    margin: rem(6) 0;
    line-height: 1.4;
    font-family: $open-sans;
    font-size: 12.5px;
    color: $text-red;
  }
}
