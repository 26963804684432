// Modifier classes for baseline list elements

@use 'sass:math';
@use '../../tools' as *;

:is(ul, ol) {
  &.no-bullets li, &.no-bullets-shallow > li {
    @extend %remove-bookend-margins;
    list-style: none;
    margin: 0.7em 0;
  }

  &.no-bullets-shallow {
    & > li li {
      list-style-type: disc;
    }

    & > li li li {
      list-style-type: circle;
    }
  }

  // Loose lists, with larger spacing between items
  &.loose li, &.loose-shallow > li {
    @extend %remove-bookend-margins;

    &, & > :is(ul, ol) {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  // Fix bullets around left-floated content
  &.float-fix li {
    position: relative;
    overflow: hidden;
    margin-left: 0;
    padding-left: 1.3em;

    &::before {
      content: '\2022';
      position: absolute;
      top: 0.15em;
      left: 0;
      font-size: rem(25);
      line-height: math.div(14, 25);
    }
  }

  // Horizontal lists
  &.horizontal-links {
    list-style: none;
    padding: 0;

    li {
      display: inline;

      &::after {
        content: ' |';
        display: inline;
      }

      &:last-of-type::after {
        content: '';
      }
    }
  }

  // Alternating lists in two columns, across then down
  &.alternating {
    & > li {
      margin-top: 0.8rem;
    }

    @include desktop {
      @include clearfix;

      & > li {
        width: calc(50% - 1.6em);
        margin-left: 1.3em;

        &:nth-child(odd) {
          float: left;
          clear: left;
        }

        &:nth-child(even) {
          float: right;
          clear: right;
        }

        &:nth-child(-n+2) { // First two elements
          margin-top: 0;
        }

        &:nth-last-child(-n+2) { // Last two elements
          margin-bottom: 0;
        }
      }

      &.rows > li {
        &:nth-child(even) {
          margin-left: 1.9em;
          float: left;
          clear: none;
        }

        &:nth-child(odd) {
          clear: left;
        }
      }
    }
  }
}
