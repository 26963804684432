@use '../../tools' as *;

%pull-quote {
  &, &.center {
    display: block;
    float: none;
    border: 0 solid $default-accent-color;
    border-left-width: rem(7);
    margin: 1rem 0;
    padding-left: 1.5rem;
    font-family: $merriweather;
    font-size: rem(16);
    line-height: 1.8;

    @extend %accent-border;
  }

  &.left {
    margin-right: 1.5rem;
    padding: 0 1.5rem 0 0;
    border-width: 0 rem(7) 0 0;

    @include span-columns(2 of 5);

    & {
      float: left;
      clear: left;
    }
  }

  &.right {
    margin-left: 1.5rem;

    @include span-columns(2 of 5);
    @include omega;

    & {
      float: right;
      clear: right;
    }
  }

  @include mobile {
    &:is(.left, .right) {
      margin: 1rem 0;
      border-width: 0 0 0 rem(7);
      padding: 0 0 0 1.5rem;

      @include span-columns(1 of 1);

      & {
        float: none;
        clear: both;
      }
    }
  }
}

blockquote:where(:not(.roundbox)) {
  @extend %pull-quote;
}

q {
  &::before {
    content: '\201c';
  }

  &::after {
    content: '\201d';
  }

  &:is(.left, .right, .center) {
    @extend %pull-quote;
    display: block;

    &::before, &::after {
      content: none;
    }
  }
}

%callout {
  &, &.right {
    padding: 1.5rem;
    background-color: $bg-gray; // #f2f2f2
    margin: 1rem {
      right: 0;
    }

    @include span-columns(2 of 5);

    & {
      float: right;
      clear: right;
    }
  }

  &.left {
    float: left;
    clear: left;
    margin: {
      right: 1rem;
      left: 0;
    }
  }

  &.center {
    float: none;
    width: 100%;
    margin: {
      left: 0;
    }
  }

  @include mobile {
    &.left, &.right {
      margin: 1rem 0;

      @include span-columns(1 of 1);

      & {
        float: none;
        clear: both;
      }
    }
  }
}

article.dk-content {
  aside:not(.references), .callout {
    @extend %callout;

    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }

  aside:not(.references) {
    margin-top: 0;
  }

  .references {
    & > * {
      font-size: rem(12);
      line-height: 1.6;
    }
  }
}
